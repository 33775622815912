<template>
  <potager-modal
    v-bind="$props"
    :buttons="computedButtons">
    <template #body>
      Vous souhaitez changer de jour de retrait et il se trouve
      {{ products.length > 1 ? 'que les éléments présents' : 'que l\'élément présent' }} dans votre commande
      {{ products.length > 1 ? 'sont épuisés' : 'est épuisé' }} pour ce jour en particulier.

      <div class="modal-container__out-of-stock-list">
        <p class="modal-container__out-of-stock-list__subtitle">
          {{ pluralize(products.length, 'BOX ÉPUISÉE', 'BOX ÉPUISÉES') }} :
        </p>
        <p
          v-for="(product, index) in products"
          :key="`product-${index}`"
          class="modal-container__out-of-stock-list__box">
          <span class="modal-container__out-of-stock-list__box__name">
            {{ product.boxName }}
          </span>
          <span class="modal-container__out-of-stock-list__box__price">
            -{{ formatPrice(product.price) }}
          </span>
        </p>
      </div>

      <h4 class="modal-container__subtitle">
        Que souhaitez-vous faire ?
      </h4>

      <p v-if="!isOrderEmpty">
        En confirmant le changement de jour de retrait,
        {{ products.length > 1 ? 'les box épuisées seront supprimées' : 'la box épuisée sera supprimée ' }}
        de votre commande. En annulant, vous conserverez l’ancien jour de retrait et
        {{ products.length > 1 ? 'les box seront toujours présentes' : 'la box sera toujours présente' }}
        dans votre commande.
      </p>
    </template>
  </potager-modal>
</template>

<script>
import {formatPrice, pluralize} from 'PotagerLogic/Formatting';

import PotagerModal, {props} from 'UI/PotagerModal';

import {
  CONFIRM_SUBSCRIPTION_BASKET_ACTION,
  UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION
} from 'Stores/types/subscriptionBasketActionsTypes';
import {UPDATE_DELIVERY_POINT_ORDER_ACTION} from 'Stores/types/userActionsTypes';

export default {
  components: {
    PotagerModal,
  },

  props: {
    ...props,
    products: {
      type: Array,
      required: false,
      default: () => [],
    },
    order: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    action: {
      type: Function,
      required: false,
      default: null,
    },
  },

  methods: {
    pluralize,
    formatPrice,
  },

  computed: {
    isOrderEmpty() {
      return this.order.boxes.length <= this.products.length;
    },
    computedButtons() {
      const loading = [
        UPDATE_SUBSCRIPTION_BASKET_DELIVERY_POINT_ACTION,
        CONFIRM_SUBSCRIPTION_BASKET_ACTION,
        UPDATE_DELIVERY_POINT_ORDER_ACTION,
      ];

      return [
        (this.isOrderEmpty ?
          {
            label: 'Choisir un autre jour de retrait',
            loading,
          } :
          {
            label: 'Confirmer',
            loading,
            action: this.action,
          }),
        {
          label: this.isOrderEmpty ? 'Conserver la commande initiale' : 'Annuler',
          loading,
          route: {
            name: 'mon-compte_orders_detail',
            params: {
              orderId: this.order.id,
            },
          }
        },
      ];
    },
  },
};
</script>
