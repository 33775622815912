import IconPtcyCb from 'Icons/color/IconPtcyCb';
import IconPtcyVisa from 'Icons/color/IconPtcyVisa';
import IconPtcyVisa2 from 'Icons/color/IconPtcyVisa2';
import IconPtcyMastercard from 'Icons/color/IconPtcyMastercard';
import IconPtcyMastercard2 from 'Icons/color/IconPtcyMastercard2';
import IconPtcyAmex from 'Icons/color/IconPtcyAmex';
import IconPtcyAmex2 from 'Icons/color/IconPtcyAmex2';
import IconCreditCard1 from 'Icons/regular/IconCreditCard1';

import { SET_DEFAULT_CARD_ACTION } from 'Stores/types/userActionsTypes';

import ModalAddBlueCard from 'Components/modals/ModalAddBlueCard';

import { openModal } from 'Plugins/potagerModals';

import { storeDispatch } from 'Services/storeServices';

import { PaymentMethods } from 'PotagerLogic/Enums/PaymentMethods';

export default {
  ...PaymentMethods.Stripe,
  route: {
    name: 'mon-compte_payment-methods_cards',
    params: { name: PaymentMethods.Stripe.name },
  },
  defaultIcon: IconCreditCard1,
  icons: [
    IconPtcyCb,
    IconPtcyVisa,
    IconPtcyMastercard,
    IconPtcyAmex
  ],
  alternativeIcons: [
    IconPtcyCb,
    IconPtcyVisa2,
    IconPtcyMastercard2,
    IconPtcyAmex2
  ],
  loaders: {
    editSettings: SET_DEFAULT_CARD_ACTION,
    editSettingsByCardId: (cardId) => `${SET_DEFAULT_CARD_ACTION}_${cardId}`,
  },
  actions: {
    openAddModal(app, onSuccess, onClose) {
      openModal(ModalAddBlueCard, {
          isLarge: true,
        },
        (e) => {
          if (e.params?.type === 'success' && typeof onSuccess === 'function') onSuccess();
          if (typeof onClose === 'function') onClose();
        });
    },

    setDefault({
      cardId,
      isDefault
    }) {
      storeDispatch(`user/${SET_DEFAULT_CARD_ACTION}`, {
        cardId,
        isDefault
      });
    },
  },
};
