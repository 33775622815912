<template>
  <panel
    ref="panel"
    :back-route="backRoute"
    :is-loading="isLoading"
    title="Point de livraison">
    <template #header>
      <p class="text-sm.5 font-medium text-center">
        Quel jour préférez-vous être livré au point relais&nbsp;:<br>
        <b>{{ getSelectedDeliveryPoint?.groupName }}</b>
      </p>
    </template>

    <dlp-gdd-list
      v-if="getSelectedDeliveryPoint"
      :context="context"
      :delivery-point="getSelectedDeliveryPoint"
      @onSubmit="gddSubmit($event, context)" />
  </panel>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { FETCH_DELIVERY_POINT } from 'Stores/types/mapActionsTypes';
import { UPDATE_SELECTED_DELIVERY_POINT } from 'Stores/types/mapMutationsTypes';

import MetaInfoService from 'Classes/services/MetaInfoService';
import { UPDATE_DELIVERY_POINT_LOADERS } from 'Classes/Loaders';

import DeliverySubmitMixin from 'Mixins/DeliverySubmitMixin';

import DlpGddList from 'Components/deliveryPoint/DlpGddList';
import Panel from 'Components/panel/Panel';

import { Context } from 'PotagerLogic/Enums/Context';

import PotagerModal from 'UI/PotagerModal';

export default {
  mixins: [
    DeliverySubmitMixin,
  ],

  components: {
    Panel,
    DlpGddList,
  },

  data: () => ({
    selectedGDD: null,
  }),

  props: {
    context: {
      type: String,
      required: true,
    },
    backRoute: {
      type: [String, Object],
      default: undefined,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    getParams: {
      handler() {
        if (this.getParamsRegionId && this.getParamsDlpId) this.loadDlp();
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('map', [
      'getSelectedDeliveryPoint',
      'getMapPublicSearchResult',
    ]),
    getParams() {
      return this.$potagerRoute?.params || this.$route.params;
    },
    getParamsRegionId() {
      const regionId = this.$potagerRoute?.params?.deliveryPointRegionId || this.$route.params.deliveryPointRegionId;
      return Number(regionId);
    },
    getParamsDlpId() {
      const deliveryPointId = this.$potagerRoute?.params?.deliveryPointId || this.$route.params.deliveryPointId;
      return Number(deliveryPointId);
    },
    isLoading() {
      return this.$wait.is(UPDATE_DELIVERY_POINT_LOADERS) || !this.getSelectedDeliveryPoint;
    },
    title() {
      return `Votre ${this.context === Context.SubscriptionBasket ? '1er jour' : 'jour'} de livraison.`;
    },
  },

  methods: {
    ...mapMutations('map', [
      UPDATE_SELECTED_DELIVERY_POINT,
    ]),
    ...mapActions('map', [
      FETCH_DELIVERY_POINT,
    ]),
    loadDlp() {
      this.FETCH_DELIVERY_POINT({
        regionId: this.getParamsRegionId,
        id: this.getParamsDlpId,
      })
        .then(({ data }) => {
          this.trackDeliveryPointDisplayed(data, this.context);
        })
        .catch(() => {
          this.$modal.open(PotagerModal, {
            title: 'Avertissement',
            text: 'Il semblerait que le point de retrait n\'existe plus.',
            buttons: [
              {
                label: 'Faire une nouvelle recherche',
                route: this.backRoute,
              },
            ],
          });
        });
    },
  },

  head() {
    return MetaInfoService.generate({
      title: 'Sélectionnez votre jour de retrait',
    });
  },
};
</script>
