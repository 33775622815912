<template>
  <component
    ref="input"
    :is="component"
    v-bind="$props"
    v-model="value"
    @change="$emit('change', $event)"
    @onAutocomplete="$emit('onAutocomplete', $event)"
    @onBlur="$emit('onBlur', $event)"
    @onEnter="$emit('onEnter', $event)"
    @onError="$emit('onError', $event)"
    @onFocus="$emit('onFocus', $event)"
    @onKeyDown="$emit('onKeyDown', $event)"
    @onKeyUp="$emit('onKeyUp', $event)"
    @onPrefixClick="$emit('onPrefixClick', $event)"
    @onReset="$emit('onReset', $event)"
    @onSubmit="$emit('onSubmit', $event)">
    <slot />

    <template
      v-if="$slots.action"
      #action>
      <slot name="action" />
    </template>

    <template
      v-if="$slots.icon"
      #icon>
      <slot name="icon" />
    </template>

    <template #suffix>
      <potager-button
        v-if="!!inlineSubmit"
        v-gtm="inlineSubmitGtm"
        :disabled="!value"
        :is-loading="isLoading"
        :is-square="typeof inlineSubmit !== 'string'"
        :theme="inlineSubmitTheme || 'go-green'"
        class="rounded-l-none -ml-1 h-auto min-w-[50px]"
        type="submit"
        @onClick="$emit('onSubmit', value)">
        <template v-if="typeof inlineSubmit === 'string'">
          {{ inlineSubmit }}
        </template>

        <icon-search1 v-else />
      </potager-button>
    </template>
  </component>
</template>

<script>
import { propsBuilder } from 'UI/Tools';

import { props as BaseInputProps } from 'UI/Form/Input/InputMixin';
import BaseInput from 'UI/Form/Input/BaseInput';
import PasswordInput, { props as PasswordInputProps } from 'UI/Form/Input/PasswordInput';
import SelectInput, { props as SelectInputProps } from 'UI/Form/Input/SelectInput';
import AutocompleteInput, { props as AutocompleteInputProps } from 'UI/Form/Input/AutocompleteInput';
import PotagerButton from 'UI/PotagerButton';

import IconSearch1 from 'Icons/bold/IconSearch1';

export const props = {
  ...BaseInputProps,
  ...PasswordInputProps,
  ...SelectInputProps,
  ...AutocompleteInputProps,
};

export default {
  inject: ['formId'],
  components: {
    PotagerButton,
    IconSearch1
  },
  props: propsBuilder(props),
  emits: [
    'change',
    'onAutocomplete',
    'onBlur',
    'onEnter',
    'onError',
    'onFocus',
    'onKeyDown',
    'onKeyUp',
    'onPrefixClick',
    'onReset',
    'onSubmit',
    'update:modelValue'
  ],
  computed: {
    component() {
      switch (this.type) {
        case 'password':
          return PasswordInput;
        case 'select':
          return SelectInput;
        default:
          if (this.options && this.options.length > 0) {
            return AutocompleteInput;
          }
          return BaseInput;
      }
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    scrollIntoView() {
      this.$refs.input.scrollIntoView({ behavior: 'smooth' });
    },
    showError() {
      this.$refs.input.showError();
    },
  },
};
</script>
