<template>
  <!-- Template Required -->
</template>

<script>

import { mapGetters } from 'vuex';
import {
  SET_BASKET_EXPIRATION_ACKNOWLEDGE_ACTION,
} from 'Stores/types/basketActionsTypes';

import PotagerModal from 'UI/PotagerModal';

export default {
  data() {
    return {
      expired: false,
      idTimeout: null,
    };
  },
  methods: {
    close() {
      this.expired = false;
    },
    setAknowledgmentDate() {
      this.$store.dispatch(`basket/${SET_BASKET_EXPIRATION_ACKNOWLEDGE_ACTION}`)
        .then(() => {
          this.expired = true;
          this.stopTimeout();
        });
    },
    stopTimeout() {
      clearTimeout(this.idTimeout);
      this.idTimeout = null;
    },
  },
  computed: {
    ...mapGetters('basket', [
      'getExpiration',
    ]),
  },
  watch: {
    getExpiration(expiration) {
      if (!expiration) return;

      let timer = expiration.expirationTimer;
      if (timer <= 0) timer = 0;
      let { aknowledgmentDate, clearedAt } = expiration;

      if (!aknowledgmentDate) aknowledgmentDate = 0;
      if (!clearedAt) clearedAt = Math.floor(new Date().getTime() / 1000) + 1000; // TEMP SIMULATE LATER IF NO CLEAR DATE
      const now = Math.floor(new Date().getTime() / 1000);
      if (expiration.expirationDate) {
        if (now < clearedAt && aknowledgmentDate == 0 && this.idTimeout == null) {
          this.idTimeout = setTimeout(() => {
            this.setAknowledgmentDate();
          }, timer * 1000);
        } else if (now > clearedAt && aknowledgmentDate == 0) {
          this.setAknowledgmentDate();
        }
      } else {
        this.stopTimeout();
      }
    },
    expired(val) {
      if (val) {
        this.$modal.open(PotagerModal, {
          title: 'Panier expiré',
          text: `Votre panier a été vidé car vous avez dépassé la date de
            commande sur ce point de livraison pour cette semaine.`,
        });
      }
    },
  },
};
</script>
