import { getGddError } from 'PotagerLogic/Utils/DeliveryPoint/GroupDeliveryDayErrors';
import { Context } from 'PotagerLogic/Enums/Context';

export const BT003_DEFAULT_ERROR = {
  buttons: [{
    label: 'Modifier mon point de retrait',
    route: {
      'name': `account-dlp-search-step1-${Context.Basket}`,
      'params': {
        'type': 'point-relais'
      }
    }
  }],
  handle(resp) {
    const gdd = resp.data.basket.groupDeliveryDay;
    return { text: getGddError(gdd) };
  },
};

export const BT003_SUBSCRIPTION_BASKET_ERROR = {
  buttons: [{
    label: 'Modifier mon point de retrait',
    route: { name: 'tunnel-delivery-type-step1' },
  }],
  handle(resp) {
    const gdd = resp.data.basket.groupDeliveryDay;
    return { text: getGddError(gdd) };
  },
};

export default {
  default: BT003_DEFAULT_ERROR,
  'TemporaryCustomerApi.addBasketSubscription': BT003_SUBSCRIPTION_BASKET_ERROR,
  'UserApi.addSubscriptionToSubscriptionBasket': BT003_SUBSCRIPTION_BASKET_ERROR,
};
