<template>
  <potager-modal
    v-bind="$props"
    :buttons="computedButtons">
    <template #body>
      <p>
        Vous souhaitez changer de jour de retrait et
        {{ products.length > 1 ? 'il se trouve que des éléments présents' : 'il se trouve qu\'un élément présent' }}
        dans votre
        panier
        {{ products.length > 1 ? 'sont épuisés' : 'est épuisé' }} pour ce jour en particulier.
      </p>

      <div class="modal-container__out-of-stock-list">
        <p class="modal-container__out-of-stock-list__subtitle">
          {{ pluralize(products.length, 'PANIER ÉPUISÉ', 'PANIERS ÉPUISÉS') }} :
        </p>
        <p
          v-for="(product, index) in products"
          :key="index"
          class="modal-container__out-of-stock-list__box">
          <span class="modal-container__out-of-stock-list__box__name">{{ product.boxName }}</span>
          <span class="modal-container__out-of-stock-list__box__price">{{ formatPrice(product.price) }}</span>
        </p>
      </div>

      <p>
        En confirmant le changement de jour de retrait,
        {{ products.length > 1 ? 'les éléments épuisés seront supprimés' : 'l\'élément épuisé sera supprimé' }} de votre
        panier. En
        annulant, vous conserverez l’ancien jour de retrait et
        {{ products.length > 1 ? 'les éléments seront toujours présents' : 'l\'élément sera toujours présent' }} dans
        votre panier.
      </p>
    </template>
  </potager-modal>
</template>

<script>
import { formatPrice, pluralize } from 'PotagerLogic/Formatting';

import PotagerModal, { props } from 'UI/PotagerModal';

import { UPDATE_SESSION_USER_DATA_ACTION } from 'Stores/types/sessionActionsTypes';
import { UPDATE_DELIVERY_POINT_BASKET_ACTION } from 'Stores/types/basketActionsTypes';

export default {
  components: {
    PotagerModal,
  },

  props: {
    ...props,
    products: {
      type: Array,
      require: false,
      default: () => [],
    },
    action: {
      type: Function,
      required: true,
    },
  },
  computed: {
    computedButtons() {
      return [
        {
          label: 'Confirmer',
          loading: [UPDATE_SESSION_USER_DATA_ACTION, UPDATE_DELIVERY_POINT_BASKET_ACTION],
          action: this.action,
        },
        {
          label: 'Annuler',
          theme: 'stroke',
          loading: [UPDATE_SESSION_USER_DATA_ACTION, UPDATE_DELIVERY_POINT_BASKET_ACTION],
        },
      ];
    }
  },

  methods: {
    pluralize,
    formatPrice,
  },
};
</script>
