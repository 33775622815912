import {
  ADD_BOX_SHADOW_BASKET_ACTION,
  CONFIRM_SHADOW_BASKET_ACTION,
  REMOVE_ALL_BOX_SHADOW_BASKET_ACTION,
  REMOVE_BOX_SHADOW_BASKET_ACTION,
  REPLACE_SUBSCRIPTION_PRODUCT,
} from 'Stores/types/shadowBasketActionsTypes';

import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';
import OrderActionMixin from 'Mixins/OrderActionMixin';

export default {
  mixins: [PaymentMethodsMixin, OrderActionMixin,],

  methods: {
    addBoxToShadowBasket(box, order) {
      return this.$store.dispatch(`shadowBasket/${ADD_BOX_SHADOW_BASKET_ACTION}`, {
        productId: box.id,
        orderId: order.id,
      })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Erreur lors de l\'ajout au panier',
            text: error.message,
          });
        });
    },
    removeBoxToShadowBasket(box, order) {
      const remainingBox = [...order.boxes];
      const index = remainingBox.findIndex((b) => b.id === box.id);
      if (index !== -1) remainingBox.splice(index, 1);

      if (remainingBox.length === 0) {
        this.cancelOrder(order);
      } else {
        this.$store.dispatch(`shadowBasket/${REMOVE_BOX_SHADOW_BASKET_ACTION}`, {
          productId: box.id,
          orderId: order.id,
        })
          .catch((error) => {
            this.$notify({
              type: 'error',
              title: 'Erreur lors de la suppression du panier',
              text: error.message,
            });
          });
      }
    }, // all box = not all product but all product of a type
    // else would be removeAllBoxesToShadowBasket
    removeAllBoxToShadowBasket(box, order) {
      this.$store.dispatch(`shadowBasket/${REMOVE_ALL_BOX_SHADOW_BASKET_ACTION}`, {
        productId: box.id,
        orderId: order.id,
      })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Erreur lors de la suppression du panier',
            text: error.message,
          });
        });
    },
    replaceSubscriptionProduct(productIdToReplace, productId, orderId) {
      return this.$store.dispatch(`shadowBasket/${REPLACE_SUBSCRIPTION_PRODUCT}`, {
        productIdToReplace,
        productId,
        orderId,
      })
        .then(() => {
          const order = this.$store.getters['user/getOrderById'](orderId);
          this.confirmShadowBasket(order);
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Erreur lors du remplacement du panier',
            text: error.message,
          });
        });
    },
    confirmShadowBasket(order) {
      return this.$store.dispatch(`shadowBasket/${CONFIRM_SHADOW_BASKET_ACTION}`)
        .then(() => {
          this.notifyOrderStatus(order);
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Erreur lors de la confirmation du panier',
            text: error.message,
          });
        });
    },
  },
};
