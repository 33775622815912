import { getGddError } from 'PotagerLogic/Utils/DeliveryPoint/GroupDeliveryDayErrors';
import { Context } from 'PotagerLogic/Enums/Context';

export const BT005_BASKET_ERROR = {
  text: 'Le jour de retrait sélectionné est la semaine prochaine. Les produits seront disponibles jeudi.',
  buttons: [{
    label: 'Modifier mon point de retrait',
    route: {
      "name": `account-dlp-search-step1-${Context.Basket}`,
      "params": {
        "type": "point-relais"
      }
    }
  }],
  handle: (resp) => {
    const gdd = resp?.response?.data?.data?.basket?.groupDeliveryDay;
    return gdd ? { text: getGddError(gdd) } : undefined;
  },
};

export default {
  default: {
    text: 'Une erreur inattendue est survenue. Le jour de livraison pour cette commande est invalide.',
  },
  'TemporaryCustomerApi.addBox': BT005_BASKET_ERROR,
  'UserApi.addBox': BT005_BASKET_ERROR,
  'TemporaryCustomerApi.setBasketDeliveryPoint': BT005_BASKET_ERROR,
  'UserApi.setBasketDeliveryPoint': BT005_BASKET_ERROR,
  'PaymentApi.payBasketByPaiementMethods': BT005_BASKET_ERROR,
  'PaymentApi.tryFreePaymentBasket': BT005_BASKET_ERROR,
};
