import {
  ADD_RECIPE,
  REMOVE_RECIPE,
  UPDATE_SUBSCRIPTION,
  UPDATE_USER,
  UPDATE_USER_EMAIL,
  UPDATE_USER_IDENTITY,
} from 'Stores/types/userMutationsTypes';

import {
  ADD_ADDRESS_ACTION,
  ADD_RECIPE_ACTION,
  CONFIRM_ACTION,
  REMOVE_RECIPE_ACTION,
  RESET_USER_ACTION,
  UPDATE_ADDRESS_ACTION,
  UPDATE_EMAIL_ACTION,
  UPDATE_OPTINS_ACTION,
  UPDATE_USER_ACTION,
  UPDATE_USER_DATA,
  UPDATE_USER_IDENTITY_ACTION,
  UPDATE_USER_PASSWORD_ACTION,
} from 'Stores/types/userActionsTypes';

import { UPDATE_BASKET } from 'Stores/types/basketMutationsTypes';
import { UPDATE_SUBSCRIPTION_BASKET } from 'Stores/types/subscriptionBasketMutationsTypes';

import findIndex from 'lodash/findIndex';
import * as Sentry from '@sentry/vue';

import userCouponStore, { defaultState as userCouponDefaultState } from './subStores/User/UserCouponStore';
import userOrderStore, { defaultState as userOrderDefaultState } from './subStores/User/UserOrderStore';
import userPaymentStore, { defaultState as userPaymentDefaultState } from './subStores/User/UserPaymentStore';
import userSubscriptionStore, { defaultState as userSubscriptionDefaultState } from './subStores/User/UserSubscriptionStore';

import { api } from 'Plugins/potagerApiClient';

import { AlertDevices, AlertTypes } from 'PotagerLogic/Enums/Alert';

export const defaultState = {
    address: null,
    addresses: [],
    alerts: [],
    birthDate: null,
    canTest: false,
    email: '',
    firstName: '',
    hasMobileApp: false,
    id: null,
    isLoaded: false,
    lastName: '',
    mySponsorshipCode: null,
    optins: {},
    phone: '',
    recipes: [],
    region: null,
    registrationDate: '',
    ...userCouponDefaultState,
    ...userOrderDefaultState,
    ...userPaymentDefaultState,
    ...userSubscriptionDefaultState,
  };

export default {
  namespaced: true,
  state: { ...defaultState },
  getters: {
    canTest: (state) => state.canTest,
    getAddress: (state) => state.address,
    getAddresses: (state) => state.addresses,
    getBasketLimit: (s, getters, rS, rGetters) => (getters.isSubscriptionActive ? rGetters['app/getSubscriptionBasketLimit'] : rGetters['app/getOneShotBasketLimit']),
    getBirthDate: (state) => state.birthDate * 1000,
    getEmail: (state) => state.email,
    getFirstName: (state) => state.firstName,
    getHasMobileApp: (state) => state.hasMobileApp,
    getLastAddress: (state) => (state.addresses.length ? [...state.addresses.sort((a, b) => a.id < b.id)].pop() : null), // Convert php to js timestamp
    getLastName: (state) => state.lastName,
    getMySponsorshipCode: (state) => state.mySponsorshipCode,
    getOptins: (state) => state.optins,
    getPhone: (state) => state.phone,
    getRecipes: (state) => state.recipes,
    getRegion: (state) => state.region,
    getRegionId: (state) => state.region?.id,
    getRegionIdForGTM: (state) => (state.region ? state.region.id : null),
    getRegistrationDate: (state) => state.registrationDate,
    getUser: (state) => state,
    getUserAlerts: (state, getters, rootState, rootGetters) => {
      const alertTypes = [AlertTypes.Global, rootGetters['session/isLoggedIn'] ? AlertTypes.LoggedIn : AlertTypes.LoggedOut, ...state.alerts];

      return rootGetters['app/getAlerts']
        .filter(alert => alert.device !== AlertDevices.App)
        .filter((alert) => alertTypes
          .includes(alert.type));
    },
    getUserId: (state) => state.id,
    isUserLoaded: (state) => Boolean(state.id), ...userPaymentStore.getters, ...userCouponStore.getters, ...userOrderStore.getters, ...userSubscriptionStore.getters,
  },

  mutations: {
    [UPDATE_USER](state, payload) {
      const attributes = ['alerts', 'id', 'registrationDate', 'email', 'firstName', 'lastName', 'phone', 'addresses', 'address', 'subscription', 'subscriptionBasket', 'region', 'couponAutomated', 'coupons', 'allBlueCards', 'blueCards', 'recipes', 'orders', 'birthDate', 'suspendIntervals', 'optins', 'mySponsorshipCode', 'hasMobileApp', 'totalPaidOrders', 'edenredAccount', 'swileAccount', 'canTest', 'historicalOrders', 'paygreenBuyerId', 'paygreenCards', 'paymentGateway'];
      attributes.forEach((attribute) => {
        state[attribute] = payload?.[attribute] !== undefined ? payload[attribute] : state[attribute];
      });
      state.isLoaded = !!payload?.id || false;

      Sentry.setUser(payload !== {} ? {
        id: payload.id,
        region: payload.region?.id,
        email: payload.email,
        username: `${payload.firstName} ${payload.lastName}`,
      } : null);
    },
    [UPDATE_USER_IDENTITY](state, payload) {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.phone = payload.phone;
      state.birthDate = payload.birthDate;
    },
    [UPDATE_USER_EMAIL](state, email) {
      state.email = email;
    },
    [ADD_RECIPE](state, recipe) {
      state.recipes = [...state.recipes, recipe];
    },
    [REMOVE_RECIPE](state, index) {
      state.recipes.splice(index, 1);
    }, ...userPaymentStore.mutations, ...userCouponStore.mutations, ...userOrderStore.mutations, ...userSubscriptionStore.mutations,
  },

  actions: {
    [RESET_USER_ACTION]({ dispatch }) {
      dispatch(UPDATE_USER_DATA, { data: defaultState});
    },
    [UPDATE_USER_ACTION]({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_USER_ACTION, { root: true });
        api.user.me()
          .then((resp) => {
            dispatch(UPDATE_USER_DATA, resp);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_USER_ACTION, { root: true }));
      });
    },
    [UPDATE_USER_DATA]({ commit }, response) {
      const data = response.data?.data ?? response.data;
      const user = data.customer ?? data.user ?? data;
      commit(UPDATE_USER, user || {});
      commit(`basket/${UPDATE_BASKET}`, user?.basket || {}, { root: true });
      commit(`subscriptionBasket/${UPDATE_SUBSCRIPTION_BASKET}`, user?.subscriptionBasket || {}, { root: true });
      commit(UPDATE_SUBSCRIPTION, user?.subscription || {});
    },
    [UPDATE_USER_IDENTITY_ACTION]({
      commit,
      dispatch,
    }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_USER_IDENTITY_ACTION, { root: true });
        api.user.updateUser(payload.customerFirstname, payload.customerLastname, payload.customerPhone, payload.birthDate,)
          .then((resp) => {
            commit(UPDATE_USER_IDENTITY, resp.data.data);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_USER_IDENTITY_ACTION, { root: true }));
      });
    },
    [UPDATE_EMAIL_ACTION]({
      commit,
      dispatch,
    }, payload) {
      dispatch('wait/start', UPDATE_EMAIL_ACTION, { root: true });
      return new Promise((resolve, reject) => {
        api.user.updateEmail(payload.email)
          .then((resp) => {
            commit(UPDATE_USER_EMAIL, resp.data.data.email);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_EMAIL_ACTION, { root: true }));
      });
    },
    [CONFIRM_ACTION]({ dispatch }, confirmationCode) {
      dispatch('wait/start', CONFIRM_ACTION, { root: true });
      return new Promise((resolve, reject) => {
        api.user.confirmEmail(confirmationCode)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', CONFIRM_ACTION, { root: true }));
      });
    },
    [UPDATE_USER_PASSWORD_ACTION]({ dispatch }, {
      password,
      confirmPassword,
      oldPassword,
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_USER_PASSWORD_ACTION, { root: true });
        const newPassword = {
          first: password,
          second: confirmPassword,
        };
        api.user.updatePassword(newPassword, oldPassword)
          .then((resp) => resolve(resp))
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_USER_PASSWORD_ACTION, { root: true }));
      });
    },
    [ADD_RECIPE_ACTION]({
      dispatch,
      commit,
    }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', ADD_RECIPE_ACTION, { root: true });
        dispatch('wait/start', `${ADD_RECIPE_ACTION}_${payload.id}`, { root: true });
        api.user.addRecipe(payload.id)
          .then((resp) => {
            commit(ADD_RECIPE, resp.data.data);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', ADD_RECIPE_ACTION, { root: true });
            dispatch('wait/end', `${ADD_RECIPE_ACTION}_${payload.id}`, { root: true });
          });
      });
    },
    [REMOVE_RECIPE_ACTION]({
      dispatch,
      commit,
      state,
    }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', REMOVE_RECIPE_ACTION, { root: true });
        dispatch('wait/start', `${REMOVE_RECIPE_ACTION}_${payload.id}`, { root: true });
        api.user.removeRecipe(payload.id)
          .then((resp) => {
            const index = findIndex(state.recipes, ['id', payload.id]);
            commit(REMOVE_RECIPE, index);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', REMOVE_RECIPE_ACTION, { root: true });
            dispatch('wait/end', `${REMOVE_RECIPE_ACTION}_${payload.id}`, { root: true });
          });
      });
    },
    [UPDATE_OPTINS_ACTION]({
      commit,
      dispatch,
      state,
    }, optins) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_OPTINS_ACTION, { root: true });

        api.user.updateOptins({
          ...state.optins, ...optins,
        })
          .then((response) => {
            commit(UPDATE_USER, response.data.data);
            resolve(response);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_OPTINS_ACTION, { root: true }));
      });
    },

    /**
     * Adresses
     */
    [ADD_ADDRESS_ACTION]({
      dispatch,
      commit,
    }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', ADD_ADDRESS_ACTION, { root: true });
        api.user.addAddress(payload)
          .then((response) => {
            commit(UPDATE_USER, response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', ADD_ADDRESS_ACTION, { root: true }));
      });
    },
    [UPDATE_ADDRESS_ACTION]({
      dispatch,
      commit,
    }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_ADDRESS_ACTION, { root: true });
        api.user.updateAddress(payload.id, payload.address)
          .then((response) => {
            commit(UPDATE_USER, response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', UPDATE_ADDRESS_ACTION, { root: true }));
      });
    }, ...userPaymentStore.actions, ...userCouponStore.actions, ...userOrderStore.actions, ...userSubscriptionStore.actions,
  },
};
