import { mapGetters } from 'vuex';

import PotagerModal from 'UI/PotagerModal';

export default {
  computed: {
    ...mapGetters('tunnel', ['getStepRouteByName',]),
  },

  methods: {
    showNotFoundSubscriptionForRegion() {
      this.$modal.open(PotagerModal, {
        title: 'Oops !',
        text: `La formule abonnement que vous souhaitez n'est pas disponible dans votre
          région pour le moment, veuillez en choisir une autre.`,
        closable: false,
        buttons: [{
          label: 'Revenir au choix du panier',
          route: this.getStepRouteByName('boxSlider'),
        },],
      });
    },
    showUserNotInRegion() {
      this.$modal.open(PotagerModal, {
        title: 'Oops !',
        text: `Vous ne pouvez pas sélectionner ce point de retrait car vous êtes
          déjà enregistré(e) dans une autre région. Si vous souhaitez changer de
          région, veuillez contacter notre service client qui fera le nécessaire
          dans les plus brefs délais.`,
        buttons: [{
          label: 'Contacter notre service client',
          route: 'https://potagercity.force.com/serviceclient/s/article/Je-souhaite-changer-de-région-Comment-faire',
        },],
      });
    },
    showBoxNotInUserRegion() {
      this.$modal.open(PotagerModal, {
        title: 'Oops !',
        text: `Vous ne pouvez pas sélectionner ce panier car vous êtes
          déjà enregistré(e) dans une autre région. Si vous souhaitez changer de
          région, veuillez contacter notre service client qui fera le nécessaire
          dans les plus brefs délais.`,
        buttons: [{
          label: 'Contacter notre service client',
          route: 'https://potagercity.force.com/serviceclient/s/article/Je-souhaite-changer-de-région-Comment-faire',
        }],
      });
    },
  },
};
