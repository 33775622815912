<template>
  <potager-modal
    size="large"
    without-footer>
    <template #title>
      Ajouter une carte bancaire
    </template>

    <template #body>
      <div class="text-left pt-2">
        <paygreen-new-card-form
          v-show="getPaymentGateway === PaymentMethodIntegration.Paygreen"
          :name="PaymentMethods.PaygreenBankCard.name"
          context="modal"
          set-primary
          @success="onSuccess">
          <template #infos>
            <potager-info
              class="mt-8"
              font-style="normal"
              size="small">
              <template #icon>
                <icon-lock6 />
              </template>
              Paiement sécurisé
            </potager-info>
          </template>
        </paygreen-new-card-form>

        <stripe-new-card-form
          v-show="getPaymentGateway === PaymentMethodIntegration.Stripe || !getPaymentGateway"
          context="modal"
          set-primary
          @success="onSuccess">
          <template #infos>
            <potager-info
              class="mt-8"
              font-style="normal"
              size="small">
              <template #icon>
                <icon-lock6 />
              </template>
              Paiement sécurisé
            </potager-info>
          </template>
        </stripe-new-card-form>
      </div>
    </template>
  </potager-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import { PaymentMethods } from 'Classes/payment-methods';
import { PaymentMethodIntegration } from 'PotagerLogic/Enums/PaymentMethods';

import GtmMixin from 'Mixins/GtmMixin';

import PotagerModal from 'UI/PotagerModal';
import PotagerInfo from 'UI/PotagerInfo';

import StripeNewCardForm from 'Components/stripe/StripeNewCardForm';
import PaygreenNewCardForm from 'Components/paygreen/PaygreenNewCardForm';

import IconLock6 from 'Icons/bold/IconLock6';

export default {

  mixins: [
    GtmMixin,
  ],

  data: () => ({
    tracking: {
      event: 'Modal',
      name: 'ModalAddBlueCard',
    },
    PaymentMethods,
    PaymentMethodIntegration,
  }),

  computed: {
    ...mapGetters('user', [
      'getPaymentGateway',
    ]),
  },

  components: {
    PotagerModal,
    PotagerInfo,
    PaygreenNewCardForm,
    StripeNewCardForm,
    IconLock6,
  },

  methods: {
    onSuccess() {
      this.trackAction({
        ...this.tracking,
        value: 'success',
      });
      this.$modal.close({ type: 'success' });
    },
  },

};
</script>
