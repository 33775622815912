import differenceBy from 'lodash/differenceBy';

import { PRIMARY } from 'Classes/Constants';
import { SecondaryLoginPaymentMethodsArray } from 'Classes/payment-methods';
import { PaymentMethodIntegration, PaymentMethodType } from 'PotagerLogic/Enums/PaymentMethods';

import { SET_DEFAULT_CARD, UPDATE_ORDER, UPDATE_USER } from 'Stores/types/userMutationsTypes';
import {
  ADD_PAYGREEN_CARD_ACTION,
  AUTHORIZE_ORDER, EDIT_EDENRED_SETTINGS, EDIT_SWILE_SETTINGS,
  FINALIZE_STRIPE_SETUP_INTENT, GET_EDENRED_LOGIN_URL,
  GET_STRIPE_SECRET_CLIENT, GET_SWILE_LOGIN_URL, INIT_PAYGREEN_BUYER, LOGOUT_EDENRED, LOGOUT_SWILE,
  PAY_BASKET_BY_PAYMENT_METHODS_ACTION, PAY_FREE_BASKET_ACTION,
  REMOVE_CARD_ACTION,
  SET_DEFAULT_CARD_ACTION, UPDATE_ORDER_3DS_STATUS, UPDATE_USER_ACTION,
} from 'Stores/types/userActionsTypes';
import { UPDATE_BASKET } from 'Stores/types/basketMutationsTypes';

import { api } from 'Plugins/potagerApiClient';

export const defaultState = {
  paymentGateway: null,
  forcedPaymentGateway: null,

  // BLUE CARDS
  allBlueCards: [],
  blueCards: [],

  // PAYGREEN CARDS
  paygreenBuyerId: undefined,
  paygreenCards: [],

  // PAYMENTS ACCOUNTS
  edenredAccount: null,
  swileAccount: null,
};

export default {
  state: { ...defaultState },

  getters: {
    getForcedPaymentGateway: (state) => state.forcedPaymentGateway,
    getPaymentGateway: (state, getters) => {
      let gateway = state.paymentGateway;

      if (getters.canTest || import.meta.env.VITE_WEB_ENV !== 'production') {
        gateway = (state.forcedPaymentGateway || state.paymentGateway);
      }

      return (gateway || PaymentMethodIntegration.Stripe).toLowerCase();
    },

    // BLUE CARDS = Stripe Cards
    getAllBlueCards: (state) => state.allBlueCards,
    getBlueCards: (state) => state.blueCards,
    getBlueCardById: (state) => (cardId) => state.blueCards.find((card) => card.id.toString() === cardId.toString()),
    getDeprecatedCard: (state, getters) => differenceBy(state.blueCards, getters.getAllBlueCards, 'id'),

    // PAYGREEN CARDS
    getPaygreenCards: (state) => state.paygreenCards,
    getPaygreenCardById: (state) => (cardId) => state.paygreenCards.find((card) => card.id.toString() === cardId.toString()),
    getPaygreenBuyerId: (state) => state.paygreenBuyerId,
    getPaygreenCardUsableForSubscription: (s, getters) => getters.getPaygreenCards
      .filter((e) => e.usableForSubscription)?.[0],

    // PAYMENT ACCOUNTS
    getPaymentAccountByName: (state) => (name) => state[`${name}Account`],
    getPaymentAccounts: (s, getters) => SecondaryLoginPaymentMethodsArray.map((e) => {
      const { name } = e;
      const state = getters.getPaymentAccountByName(e.name);
      return state ? {
        name,
        ...state,
      } : null;
    })
      .filter((e) => e),
    getPaymentAccountUsableForSubscription: (state, getters) => getters.getPaymentAccounts
      .find((account) => account?.usableForSubscription) || null,
    canAddPaymentAccount: (s, getters) => getters.getPaymentAccounts.length < SecondaryLoginPaymentMethodsArray.length,

    // GETTERS BY TYPES OF PAYMENT METHODS
    // We have two type of cards/accounts :
    // Bank cards and Restaurant cards
    // Bank cards are all blue cards and paygreen cards with brand 'bank_card'
    // Restaurant cards are paygreen cards with brand 'conecs' (!= bank_card)
    // AND account edenred & swile
    // priority === 'PRIMARY' means that the stripe card is by default
    // usableForSubscription === true means that the paygreen card is by default
    // there is only one default card by type of payment method

    // BANK CARDS
    getBankCards: (s, getters) => [
      ...getters.getBlueCards,
      ...getters.getPaygreenCards
        .filter((e) => e.brand === PaymentMethodType.BankCard),
    ],
    getDefaultBankCard: (s, getters) => getters.getBankCards.find((e) => e.priority === PRIMARY || e.usableForSubscription || e.isActiveForSubscription),

    // RESTAURANT CARDS
    getRestaurantCards: (s, getters) => [
      ...getters.getPaygreenCards.filter((e) => e.brand === 'conecs'),
    ],
    getRestaurantPMs: (s, getters) => [
      ...getters.getRestaurantCards,
      ...getters.getPaymentAccounts,
    ],
    getDefaultRestaurantPM: (s, getters) => getters.getRestaurantPMs.find((e) => e.usableForSubscription || e.isActiveForSubscription),

    // GENERAL
    getCardById: (s, getters) => (cardId) => [
      ...getters.getBankCards,
      ...getters.getRestaurantCards,
    ].find((e) => e.id === cardId),
    getAllCards: (s, getters) => [
      ...getters.getBankCards,
      ...getters.getRestaurantCards,
      ...getters.getPaymentAccounts,
    ],
  },

  mutations: {
    // PRIMARY CARDS
    [SET_DEFAULT_CARD](state, payload) {
      state.blueCards = payload.blueCards;
      state.paygreenCards = payload.paygreenCards;
    },
    setPaymentGateway(state, payload) {
      state.paymentGateway = payload;
    },
    setForcedPaymentGateway(state, payload) {
      state.forcedPaymentGateway = payload;
    },
  },

  actions: {
    // Usable for BANK and RESTAURANT cards
    [SET_DEFAULT_CARD_ACTION]({
      commit,
      dispatch
    }, {
      cardId,
      isDefault = true
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', SET_DEFAULT_CARD_ACTION, { root: true });
        dispatch('wait/start', `${SET_DEFAULT_CARD_ACTION}_${cardId}`, { root: true });
        api.user.setDefaultPaymentMethod(cardId, { isActiveForSubscription: !!isDefault })
          .then((resp) => {
            commit(UPDATE_USER, resp.data.data);
            commit(`basket/${UPDATE_BASKET}`, resp.data.data.basket, { root: true });
            commit(SET_DEFAULT_CARD, resp.data.data);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', SET_DEFAULT_CARD_ACTION, { root: true });
            dispatch('wait/end', `${SET_DEFAULT_CARD_ACTION}_${cardId}`, { root: true });
          });
      });
    },
    // stripe
    [GET_STRIPE_SECRET_CLIENT]({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', GET_STRIPE_SECRET_CLIENT, { root: true });
        api.payment.getClientSecret()
          .then((response) => resolve(response))
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', GET_STRIPE_SECRET_CLIENT, { root: true }));
      });
    },
    [FINALIZE_STRIPE_SETUP_INTENT]({
      dispatch,
      commit
    }, {
      setupIntentId,
      setPrimaryBlueCard
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', FINALIZE_STRIPE_SETUP_INTENT, { root: true });
        api.payment.finalizeSetupIntent({
          setup_intent_id: setupIntentId,
          is_default_payment_method: setPrimaryBlueCard,
        })
          .then((response) => {
            commit(UPDATE_USER, response.data);
            resolve(response);
          })
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', FINALIZE_STRIPE_SETUP_INTENT, { root: true }));
      });
    },
    [AUTHORIZE_ORDER]({
      dispatch,
      commit
    }, {
      orderId,
      cardId
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', AUTHORIZE_ORDER, { root: true });
        api.order.authorizeOrder({
          orderId,
          cardId,
        })
          .then((response) => {
            const order = response.data.data;
            commit(UPDATE_ORDER, order);
            resolve(response);
          })
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', AUTHORIZE_ORDER, { root: true }));
      });
    },
    [UPDATE_ORDER_3DS_STATUS]({
      dispatch,
      commit
    }, paymentIntentId) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_ORDER_3DS_STATUS, { root: true });
        api.payment.updateOrder3dsStatus(paymentIntentId)
          .then((response) => {
            const order = response.data.data;
            commit(UPDATE_ORDER, order);
            resolve(response);
          })
          .catch((err) => {
            const order = err.response.data.data;
            commit(UPDATE_ORDER, order);
            reject(err);
          })
          .finally(() => {
            dispatch('wait/end', UPDATE_ORDER_3DS_STATUS, { root: true });
            dispatch(UPDATE_USER_ACTION);
          });
      });
    },
    [REMOVE_CARD_ACTION]({
      commit,
      dispatch
    }, cardId) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', `${REMOVE_CARD_ACTION}_${cardId}`, { root: true });
        api.user.removePaymentMethod(cardId)
          .then((resp) => {
            commit(UPDATE_USER, resp.data.data);
            commit(`basket/${UPDATE_BASKET}`, resp.data.data.basket, { root: true });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', `${REMOVE_CARD_ACTION}_${cardId}`, { root: true }));
      });
    },

    // SECONDARY CARDS
    [INIT_PAYGREEN_BUYER]({
      dispatch,
      commit
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', INIT_PAYGREEN_BUYER, { root: true });
        api.paygreen.initBuyer()
          .then((response) => {
            commit(UPDATE_USER, response.data.data);
            resolve(response.data.data.paygreenBuyerId);
          })
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', INIT_PAYGREEN_BUYER, { root: true }));
      });
    },
    [ADD_PAYGREEN_CARD_ACTION]({
      dispatch,
      commit
    }, instrumentId) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', ADD_PAYGREEN_CARD_ACTION, { root: true });
        api.paygreen.addCard(instrumentId)
          .then((response) => {
            if (response.data.success) {
              const { data } = response.data;
              commit(UPDATE_USER, data);
              resolve(data.paygreenCards[data.paygreenCards.length - 1]);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', ADD_PAYGREEN_CARD_ACTION, { root: true }));
      });
    },

    // SECONDARY ACCOUNTS
    // edenred
    [GET_EDENRED_LOGIN_URL]({ dispatch }, redirectUrl = null) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', GET_EDENRED_LOGIN_URL, { root: true });
        api.edenred.getLoginUrl(redirectUrl)
          .then((response) => resolve(response))
          .catch((error) => {
            dispatch('wait/end', GET_EDENRED_LOGIN_URL, { root: true });
            reject(error);
          });
      });
    },
    [EDIT_EDENRED_SETTINGS]({
      dispatch,
      commit
    }, settings = {}) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', EDIT_EDENRED_SETTINGS, { root: true });
        api.edenred.edit(settings)
          .then((response) => {
            const { data } = response.data;
            commit(UPDATE_USER, data);
            resolve(response);
          })
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', EDIT_EDENRED_SETTINGS, { root: true }));
      });
    },
    [LOGOUT_EDENRED]({
      dispatch,
      commit
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', LOGOUT_EDENRED, { root: true });
        api.edenred.logout()
          .then((response) => {
            commit(UPDATE_USER, response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', LOGOUT_EDENRED, { root: true }));
      });
    },

    // swile
    [GET_SWILE_LOGIN_URL]({ dispatch }, redirectUrl = null) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', GET_SWILE_LOGIN_URL, { root: true });
        api.swile.getLoginUrl(redirectUrl)
          .then((response) => resolve(response))
          .catch((error) => {
            dispatch('wait/end', GET_SWILE_LOGIN_URL, { root: true });
            reject(error);
          });
      });
    },
    [EDIT_SWILE_SETTINGS]({
      dispatch,
      commit
    }, settings = {}) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', EDIT_SWILE_SETTINGS, { root: true });
        api.swile.edit(settings)
          .then((response) => {
            const { data } = response.data;
            commit(UPDATE_USER, data);
            resolve(response);
          })
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', EDIT_SWILE_SETTINGS, { root: true }));
      });
    },
    [LOGOUT_SWILE]({
      dispatch,
      commit
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', LOGOUT_SWILE, { root: true });
        api.swile.logout()
          .then((response) => {
            commit(UPDATE_USER, response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', LOGOUT_SWILE, { root: true }));
      });
    },

    // PAY
    [PAY_BASKET_BY_PAYMENT_METHODS_ACTION]({
      dispatch,
      commit
    }, paymentMethods = []) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', PAY_BASKET_BY_PAYMENT_METHODS_ACTION, { root: true });
        api.payment.payBasketByPaiementMethods(paymentMethods)
          .then((response) => {
            const data = response.data?.data || response.data;
            commit(UPDATE_USER, data);
            commit(`basket/${UPDATE_BASKET}`, data.basket, { root: true });

            if (data.orders) {
              const newOrder = data.orders[0];
              commit(UPDATE_ORDER, newOrder);
              // On met à jour l'utilisateur si la commande n'est pas en attente d'une action
              if (newOrder && newOrder.stripePayment.neededAction !== '3ds') {
                dispatch(UPDATE_USER_ACTION)
                  .finally(() => resolve(response));
              } else {
                resolve(response);
              }
            } else {
              resolve(response);
            }
          })
          .catch((error) => reject(error))
          .finally(() => dispatch('wait/end', PAY_BASKET_BY_PAYMENT_METHODS_ACTION, { root: true }));
      });
    },
    [PAY_FREE_BASKET_ACTION]({
      dispatch,
      commit
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', PAY_FREE_BASKET_ACTION, { root: true });
        api.payment.tryFreePaymentBasket()
          .then((response) => {
            if (response.data.data.orders) commit(UPDATE_ORDER, response.data.data.orders[0]);
            dispatch(UPDATE_USER_ACTION)
              .finally(() => resolve(response));
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', PAY_FREE_BASKET_ACTION, { root: true }));
      });
    },
  },
};
