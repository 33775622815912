<template>
  <div class="coupon-automated panel-section">
    <div class="coupon-automated__container panel-section__line">
      <div class="coupon-automated__header">
        <icon-robot2 class="coupon-automated__header__robot" />

        <div class="coupon-automated__header__text">
          Application automatique des coupons
        </div>
      </div>

      <template v-if="getUserCouponAutomatedConfiguration.isActivated">
        <p class="coupon-automated__intro">
          À la création automatique de vos commandes,
          notre robot appliquera vos coupons disponibles
          avec les options suivantes :
        </p>

        <ul class="coupon-automated__param">
          <li class="coupon-automated__param__item">
            Perte acceptable max. : {{ getUserCouponAutomatedConfiguration.acceptableLoss }}€
          </li>

          <li class="coupon-automated__param__item">
            Nombre de coupons maximum à utiliser par commande :
            {{
              getUserCouponAutomatedConfiguration.nbMaxCoupons === -1 ? 'Pas de limite' : getUserCouponAutomatedConfiguration.nbMaxCoupons
            }}
          </li>
        </ul>
        <div class="btn-group">
          <potager-button
            :to="{ name: 'mon-compte_coupons_automated' }"
            full-width
            label="Modifier"
            theme="white" />

          <potager-button
            :is-loading="$wait.is('UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION')"
            full-width
            label="Désactiver"
            theme="stroke-white"
            @onClick="disableCouponAutomated" />
        </div>
      </template>

      <template v-else>
        <p class="coupon-automated__intro">
          À la création automatique de vos commandes,
          notre robot appliquera vos coupons disponibles
          jusqu’à épuisement.
        </p>

        <potager-button
          class="mb-4"
          size="small"
          theme="stroke-white"
          @onClick="openExplanationModal">
          En savoir plus
        </potager-button>

        <potager-button
          :to="{ name: 'mon-compte_coupons_automated' }"
          full-width
          label="Commencer"
          theme="white" />
      </template>
    </div>
  </div>
</template>

<script>
import IconRobot2 from 'Icons/regular/IconRobot2';

import PotagerButton from 'UI/PotagerButton';

import { UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION } from 'Stores/types/userActionsTypes';
import { mapGetters } from 'vuex';
import PotagerModal from 'UI/PotagerModal';

export default {

  components: {
    IconRobot2,
    PotagerButton,
  },

  computed: {
    ...mapGetters('user', [
      'getUserCouponAutomatedConfiguration',
    ]),
  },

  methods: {
    openExplanationModal() {
      this.$modal.open(PotagerModal, {
        title: 'Application automatique des coupons',
        text: 'En activant cette option, vous permettez à notre robot d\'appliquer de manière automatique vos coupons (hors coupons de type pourcentage) lorsque vos commandes sont créées. L\'algorithme appliquera autant de coupons que possible, en prenant ceux ayant les valeurs les plus hautes en premier. Vous aurez jusqu\'à l\'avant-veille de la livraison de votre commande pour modifier cette application si elle ne vous convient pas.',
      });
    },
    disableCouponAutomated() {
      this.$store.dispatch(`user/${UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION}`, {
        isCouponAutomated: false,
        acceptableLoss: parseInt(this.getUserCouponAutomatedConfiguration.acceptableLoss, 10),
        maxCouponAutomated: this.getUserCouponAutomatedConfiguration.nbMaxCoupons,
      });
    },
  },

};
</script>
