<template>
  <div
    :class="[
      'card-v2__tools z-20 text-sm font-bold',
      'flex rounded-full',
      skeleton ? 'bg-white-rock' : !editable ? 'bg-white p-2.5' : quantity > 0 ? 'bg-go-green' : 'bg-beryl-green',
      { 'animate-pulse': skeleton },
    ]"
    @click.stop.prevent>
    <potager-button
      v-if="!hideMinus"
      key="minus"
      :size="$mq.bp375 ? 'small' : undefined"
      :theme="buttonTheme"
      class="card-v2__tools__btn"
      is-circle
      prevent
      @onClick="deleteBox">
      <template #icon>
        <icon-subtract class="text-sm" />
      </template>
    </potager-button>

    <potager-button
      v-if="isLoading || skeleton"
      key="loader"
      :class="[
        'card-v2__tools__loader card-v2__tools__btn',
        { 'text-sm card-v2__tools__quantity': !(quantity === 0 || isLock) }
      ]"
      :size="$mq.bp375 ? 'small' : undefined"
      :theme="buttonTheme"
      is-circle
      prevent>
      <icon-ptcy-loader-spinner
        v-if="!skeleton"
        :class="[
          'leading-0',
          !(quantity === 0 || isLock) ? 'text-sm' : 'text-lg.5',
        ]" />
    </potager-button>

    <div
      v-else-if="!editable && quantity"
      class="h-5 min-w-5 text-center">
      {{ quantity }}
    </div>

    <potager-button
      v-else-if="isLock"
      :class="[
        'card-v2__tools__btn card-v2__tools__btn--lock',
        {'cursor-not-allowed': quantity === 0},
      ]"
      :is-loading="isLoading"
      :size="$mq.bp375 ? 'small' : undefined"
      is-circle
      @onClick="() => quantity > 0 ? deleteBox() : undefined">
      <template #icon>
        <icon-bin1 v-if="quantity && quantity > 0" />
        <icon-lock6 v-else />
      </template>
    </potager-button>

    <component
      v-else-if="!hideMinus"
      key="quantity"
      :is="!order ? 'button' : 'span'"
      :class="{
        'card-v2__tools__quantity': true,
        'card-v2__tools__quantity--edit': !order,
      }"
      @click="!order ? editQuantity : undefined">
      {{ quantity }}
    </component>

    <potager-button
      v-if="!hidePlus"
      key="plus"
      :is-disabled="isLock || isLoading"
      :size="$mq.bp375 ? 'small' : undefined"
      :theme="buttonTheme"
      class="card-v2__tools__btn"
      is-circle
      prevent
      @onClick="addBox">
      <template #icon>
        <icon-add class="text-sm" />
      </template>
    </potager-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerButton from 'UI/PotagerButton';

import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';
import IconLock6 from 'Icons/bold/IconLock6';
import IconAdd from 'Icons/bold/IconAdd';
import IconSubtract from 'Icons/bold/IconSubtract';
import IconBin1 from 'Icons/bold/IconBin1';

import { getDlpSearchRoute } from 'Classes/utils/DlpRouteUtils';

import ModalEditProductQuantity from 'Modals/ModalEditProductQuantity';

import basketActionMixin from 'Mixins/BasketActionMixin';
import ShadowBasketActionMixin from 'Mixins/ShadowBasketActionMixin';
import {
  ADD_BOX_SHADOW_BASKET_ACTION, REMOVE_ALL_BOX_SHADOW_BASKET_ACTION,
  REMOVE_BOX_SHADOW_BASKET_ACTION
} from 'Stores/types/shadowBasketActionsTypes';
import {
  ADD_BOX_BASKET_ACTION, EDIT_BOX_QUANTITY_BASKET_ACTION,
  REMOVE_BOX_BASKET_ACTION
} from 'Stores/types/basketActionsTypes';

export default {
  mixins: [
    basketActionMixin,
    ShadowBasketActionMixin
  ],

  components: {
    PotagerButton,
    IconBin1,
    IconLock6,
    IconAdd,
    IconSubtract,
    IconPtcyLoaderSpinner,
  },

  props: {
    quantity: {
      type: Number,
      required: false,
      default: 0,
    },
    order: {
      type: Object,
      required: false,
      default: null,
    },
    box: {
      type: Object,
      required: false,
      default: null,
    },
    skeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('basket', [
      'canContainMiniBox',
      'getDeliveryPoint',
    ]),
    isLoading() {
      return this.box ? this.$wait.is([
        `${ADD_BOX_BASKET_ACTION}_${this.box.id}`,
        `${REMOVE_BOX_BASKET_ACTION}_${this.box.id}`,
        `${ADD_BOX_SHADOW_BASKET_ACTION}_${this.box.id}`,
        `${REMOVE_BOX_SHADOW_BASKET_ACTION}_${this.box.id}`,
        `${EDIT_BOX_QUANTITY_BASKET_ACTION}_${this.box.id}`,
        REMOVE_ALL_BOX_SHADOW_BASKET_ACTION,
      ]) : false;
    },
    isNotAvailable() {
      return !this.box?.deliveryDate?.isCurrentWeek;
    },
    isLock() {
      return !this.canContainMiniBox || this.box?.isSoldOut || this.isNotAvailable || this.box?.isAssociableToVouchers;
    },
    editable() {
      return !this.order || this.order?.isEditable;
    },
    hideMinus() {
      return !this.editable || this.quantity <= 0 || (this.isLoading && this.quantity <= 0) || this.isLock || this.skeleton;
    },
    hidePlus() {
      return !this.editable || (this.quantity <= 0 && this.isLoading) || this.isLock || this.skeleton;
    },
    buttonTheme() {
      if (this.skeleton) return 'white-rock';
      if (this.isLock) return undefined;
      return this.quantity > 0 ? 'go-green' : 'beryl-green';
    },
  },

  methods: {
    editQuantity(e) {
      e.preventDefault();

      this.$modal.open(ModalEditProductQuantity,
        {
          initialQuantity: this.quantity,
          onSubmit: (newQuantity) => this.editBoxQuantityToBasket(this.box, newQuantity),
        });
    },
    addBox() {
      if (!this.order && !this.getDeliveryPoint) {
        this.$router.push(getDlpSearchRoute());
      } else if (this.order) {
        this.addBoxToShadowBasket(this.box, this.order);
      } else {
        this.addBoxToBasket(this.box);
      }
    },
    deleteBox() {
      if (this.order) {
        this.removeBoxToShadowBasket(this.box, this.order);
      } else {
        this.removeBoxToBasket(this.box);
      }
    },
    deleteAllBox() {
      if (this.order) {
        this.removeAllBoxToShadowBasket(this.box, this.order);
      } else {
        this.editBoxQuantityToBasket(this.box, 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-v2__tools {
  @apply flex justify-end relative;

  :deep(.potager-button--disabled) {
    opacity: 0.5;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    @apply text-xl.5;
  }

  &__quantity {
    position: relative;
    line-height: 1em;
    @apply z-30 h-5 w-5 rounded-full text-white bg-go-green/80;
    @apply flex justify-center items-center;
    @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;

    &--edit {
      &:before {
        content: '';
        height: 2.5rem;
        width: 2.5rem;
        background: rgba($charcoal, .1);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.5);
        border-radius: 50%;
        transition: transform .3s, opacity .3s;
        opacity: 0;
      }

      @include hover {
        &:before {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
}
</style>
