import {
  ADD_COUPON_LUNCHEON_BATCH,
  ADD_LOCAL_COUPON_LUNCHEON_BATCH,
  DELETE_LOCAL_COUPON_LUNCHEON_BATCH,
  UPDATE_COUPON_AUTOMATED_CONFIGURATION,
  UPDATE_COUPON_AUTOMATED_VALUE,
  UPDATE_COUPON_LUNCHEONS_BATCH,
  UPDATE_LOCAL_COUPON_LUNCHEONS_BATCH,
  UPDATE_ORDER,
  UPDATE_USER_COUPONS,
} from 'Stores/types/userMutationsTypes';

import {
  ADD_COUPON_LUNCHEON_BATCH_ACTION,
  ADD_COUPON_ON_BASKET_ACTION,
  ADD_COUPON_ON_ORDER_ACTION,
  ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
  ADD_COUPON_ON_USER_ACTION,
  UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION,
  UPDATE_COUPON_AUTOMATED_VALUE_ACTION,
  UPDATE_COUPON_LUNCHEON_BATCH_ACTION,
  UPDATE_USER_ACTION,
  REMOVE_COUPON_LUNCHEON_BATCH_ACTION,
  REMOVE_COUPON_ON_BASKET_ACTION,
  REMOVE_COUPON_ON_ORDER_ACTION,
  REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
} from 'Stores/types/userActionsTypes';

import { UPDATE_SESSION_USER_DATA_ACTION } from 'Stores/types/sessionActionsTypes';

import { SUBSCRIPTION_BASKET_TYPE } from 'Classes/Constants';

import { api } from 'Plugins/potagerApiClient';

import { CouponType } from 'PotagerLogic/Enums/Coupons';

// loaders
export const COUPONS_BASKET_LOADERS = [
  ADD_COUPON_ON_BASKET_ACTION,
  REMOVE_COUPON_ON_BASKET_ACTION,
];

export const COUPONS_ORDER_LOADERS = [
  ADD_COUPON_ON_ORDER_ACTION,
  REMOVE_COUPON_ON_ORDER_ACTION,
];

export const COUPONS_SUBSCRIPTION_BASKET_LOADERS = [
  ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
  REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
];

export const COUPONS_LOADERS = [
  ...COUPONS_BASKET_LOADERS,
  ...COUPONS_ORDER_LOADERS,
  ...COUPONS_SUBSCRIPTION_BASKET_LOADERS,
];

export const defaultState = {
  couponAutomated: {
    isActivated: false,
    acceptableLoss: null,
    nbMaxCoupons: null,
  },
  coupons: [],

  // coupons titres-resto (papier)
  couponLuncheonsBatch: [],
  localCouponLuncheonsBatch: [],
};

export default {
  state: { ...defaultState },

  getters: {
    getUserCoupons: (state) => (state.coupons ? state.coupons : []),
    getUserCouponsLuncheon: (state) => state.coupons.filter((cp) => cp.couponType === CouponType.Luncheon),
    getUserCouponsExceptLuncheon: (state) => state.coupons.filter((cp) => cp.couponType !== CouponType.Luncheon),
    getUserCouponAutomatedConfiguration: (state) => state.couponAutomated,

    // coupons titres-resto (papier)
    getUserCouponLuncheonsBatch: (state) => state.couponLuncheonsBatch?.filter((coupon) => !coupon.isDeleted),
    getUserLocalCouponLuncheonsBatch: (state) => state.localCouponLuncheonsBatch || [],
  },

  mutations: {
    [UPDATE_COUPON_AUTOMATED_CONFIGURATION](state, {
      isCouponAutomated,
      acceptableLoss,
      maxCouponAutomated
    }) {
      state.couponAutomated = {
        isActivated: isCouponAutomated,
        acceptableLoss,
        nbMaxCoupons: maxCouponAutomated,
      };
    },
    [UPDATE_COUPON_AUTOMATED_VALUE](state, {
      couponId,
      isAutomated
    }) {
      const couponIndex = state.coupons.findIndex((cp) => parseInt(cp.id, 10) === parseInt(couponId, 10));
      state.coupons[couponIndex].isAutomated = isAutomated;
    },
    [UPDATE_USER_COUPONS](state, coupons) {
      state.coupons = coupons;
    },

    // coupons titres-resto (papier)
    [UPDATE_COUPON_LUNCHEONS_BATCH](state, couponLuncheonsBatch) {
      state.couponLuncheonsBatch = typeof couponLuncheonsBatch === 'undefined' ? [] : couponLuncheonsBatch;
    },
    [ADD_COUPON_LUNCHEON_BATCH](state, couponLuncheonBatch) {
      state.couponLuncheonsBatch.push(couponLuncheonBatch);
    },
    [UPDATE_LOCAL_COUPON_LUNCHEONS_BATCH](state, couponLuncheonsBatch) {
      state.localCouponLuncheonsBatch = !couponLuncheonsBatch ? [] : couponLuncheonsBatch;
    },
    [ADD_LOCAL_COUPON_LUNCHEON_BATCH](state, couponLuncheonBatch) {
      if (couponLuncheonBatch.tickets) {
        state.localCouponLuncheonsBatch = [
          ...state.localCouponLuncheonsBatch,
          ...couponLuncheonBatch.tickets,
        ];
      }
    },
    [DELETE_LOCAL_COUPON_LUNCHEON_BATCH](state, couponLuncheon) {
      if (couponLuncheon && (couponLuncheon.code || couponLuncheon.barcode)) {
        state.localCouponLuncheonsBatch = state.localCouponLuncheonsBatch.filter((ticket) => {
          const code = couponLuncheon.code || couponLuncheon.barcode;
          return ticket.code !== code;
        });
      }
    },
  },

  actions: {
    // coupons automated
    [UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION]({
      commit,
      dispatch
    }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION, { root: true });
        api.user.updateCouponAutomatedConfiguration(payload.isCouponAutomated, payload.acceptableLoss, payload.maxCouponAutomated,)
          .then((resp) => {
            const { isCouponAutomated } = resp.data.data.customer;
            const { acceptableLoss } = resp.data.data.customer;
            const maxCouponAutomated = resp.data.data.customer.maxAutomatedCoupon;
            commit(UPDATE_COUPON_AUTOMATED_CONFIGURATION, {
              isCouponAutomated,
              acceptableLoss,
              maxCouponAutomated,
            });
            dispatch(`session/${UPDATE_SESSION_USER_DATA_ACTION}`, resp, { root: true });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION, { root: true }));
      });
    },
    [UPDATE_COUPON_AUTOMATED_VALUE_ACTION]({
      commit,
      dispatch
    }, {
      couponId,
      isAutomated
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_COUPON_AUTOMATED_VALUE_ACTION, { root: true });
        dispatch('wait/start', `${UPDATE_COUPON_AUTOMATED_VALUE_ACTION}_${couponId}`, { root: true });
        api.user.updateCouponAutomatedState(couponId, isAutomated)
          .then((resp) => {
            commit(UPDATE_COUPON_AUTOMATED_VALUE, {
              couponId: resp.data.data.id,
              isAutomated: resp.data.data.isAutomated,
            });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', UPDATE_COUPON_AUTOMATED_VALUE_ACTION, { root: true });
            dispatch('wait/end', `${UPDATE_COUPON_AUTOMATED_VALUE_ACTION}_${couponId}`, { root: true });
          });
      });
    },

    // coupons user
    [ADD_COUPON_ON_USER_ACTION]({ dispatch }, { couponCode }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', ADD_COUPON_ON_USER_ACTION, { root: true });
        api.user.addCouponByCode(couponCode)
          .then((resp) => {
            dispatch(`session/${UPDATE_SESSION_USER_DATA_ACTION}`, resp, { root: true });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', ADD_COUPON_ON_USER_ACTION, { root: true }));
      });
    },

    // coupons order
    [ADD_COUPON_ON_ORDER_ACTION]({
      commit,
      dispatch
    }, {
      couponCode,
      orderId
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', ADD_COUPON_ON_ORDER_ACTION, { root: true });
        dispatch('wait/start', `${ADD_COUPON_ON_ORDER_ACTION}_${couponCode}`, { root: true });

        const onEnd = () => {
          dispatch('wait/end', ADD_COUPON_ON_ORDER_ACTION, { root: true });
          dispatch('wait/end', `${ADD_COUPON_ON_ORDER_ACTION}_${couponCode}`, { root: true });
        };

        api.user.addCouponOnOrderByCode(couponCode, orderId)
          .then((response) => {
            dispatch(UPDATE_USER_ACTION)
              .then(() => resolve(response))
              .catch((err) => reject(err))
              .finally(() => {
                commit(UPDATE_ORDER, response.data.data);
                onEnd();
              });
          })
          .catch((err) => {
            onEnd();
            reject(err);
          });
      });
    },
    [REMOVE_COUPON_ON_ORDER_ACTION]({
      commit,
      dispatch
    }, {
      couponId,
      orderId
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', REMOVE_COUPON_ON_ORDER_ACTION, { root: true });
        dispatch('wait/start', `${REMOVE_COUPON_ON_ORDER_ACTION}_${couponId}`, { root: true });

        const onEnd = () => {
          dispatch('wait/end', REMOVE_COUPON_ON_ORDER_ACTION, { root: true });
          dispatch('wait/end', `${REMOVE_COUPON_ON_ORDER_ACTION}_${couponId}`, { root: true });
        };

        api.user.removeCouponFromOrder(couponId, orderId)
          .then((response) => {
            dispatch(UPDATE_USER_ACTION)
              .then(() => resolve(response))
              .catch((err) => reject(err))
              .finally(() => {
                commit(UPDATE_ORDER, response.data.data);
                onEnd();
              });
          })
          .catch((err) => {
            onEnd();
            reject(err);
          });
      });
    },

    // coupons basket
    [ADD_COUPON_ON_BASKET_ACTION]({
      dispatch,
      rootGetters
    }, { couponCode }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', ADD_COUPON_ON_BASKET_ACTION, { root: true });
        dispatch('wait/start', `${ADD_COUPON_ON_BASKET_ACTION}_${couponCode}`, { root: true });

        const addCoupon = () => rootGetters['user/getUserId']
          ? api.user.addCouponBasketByCode(couponCode)
          : api.temporaryCustomer.addCouponBasketByCode(rootGetters['temporaryCustomer/getTemporaryCustomerId'], rootGetters['session/getRegionId'], couponCode);

        addCoupon()
          .then((resp) => {
            dispatch(`session/${UPDATE_SESSION_USER_DATA_ACTION}`, resp, { root: true });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', ADD_COUPON_ON_BASKET_ACTION, { root: true });
            dispatch('wait/end', `${ADD_COUPON_ON_BASKET_ACTION}_${couponCode}`, { root: true });
          });
      });
    },
    [REMOVE_COUPON_ON_BASKET_ACTION]({
      dispatch,
      rootGetters
    }, { couponId }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', REMOVE_COUPON_ON_BASKET_ACTION, { root: true });
        dispatch('wait/start', `${REMOVE_COUPON_ON_BASKET_ACTION}_${couponId}`, { root: true });

        const removeCoupon = rootGetters['user/getUserId'] ? () => api.user.removeCouponBasket(couponId) : () => api.temporaryCustomer.removeCouponBasket(rootGetters['temporaryCustomer/getTemporaryCustomerId'], rootGetters['session/getRegionId'], couponId);

        removeCoupon()
          .then((resp) => {
            dispatch(`session/${UPDATE_SESSION_USER_DATA_ACTION}`, resp, { root: true });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', REMOVE_COUPON_ON_BASKET_ACTION, { root: true });
            dispatch('wait/end', `${REMOVE_COUPON_ON_BASKET_ACTION}_${couponId}`, { root: true });
          });
      });
    },

    // coupons subscription basket
    [ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION]({ dispatch }, { couponCode }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION, { root: true });
        dispatch('wait/start', `${ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION}_${couponCode}`, { root: true });
        api.user.addCouponBasketByCode(couponCode, SUBSCRIPTION_BASKET_TYPE)
          .then((resp) => {
            dispatch(`session/${UPDATE_SESSION_USER_DATA_ACTION}`, resp, { root: true });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION, { root: true });
            dispatch('wait/end', `${ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION}_${couponCode}`, { root: true });
          });
      });
    },
    [REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION]({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION, { root: true });
        dispatch('wait/start', `${REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION}_${payload.couponId}`, { root: true });
        api.user.removeCouponBasket(payload.couponId, SUBSCRIPTION_BASKET_TYPE)
          .then((resp) => {
            dispatch(`session/${UPDATE_SESSION_USER_DATA_ACTION}`, resp, { root: true });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION, { root: true });
            dispatch('wait/end', `${REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION}_${payload.couponId}`, { root: true });
          });
      });
    },

    // coupons titres-resto (papier)
    [ADD_COUPON_LUNCHEON_BATCH_ACTION]({
      commit,
      dispatch
    }, {
      tickets,
      simulation = false
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', ADD_COUPON_LUNCHEON_BATCH_ACTION, { root: true });
        api.user.addCouponLuncheonsBatch(tickets, simulation)
          .then((response) => {
            if (!simulation) {
              commit(ADD_COUPON_LUNCHEON_BATCH, response.data.data);
              commit(UPDATE_LOCAL_COUPON_LUNCHEONS_BATCH, null);
            } else {
              commit(ADD_LOCAL_COUPON_LUNCHEON_BATCH, response.data.data);
            }
            resolve(response);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', ADD_COUPON_LUNCHEON_BATCH_ACTION, { root: true }));
      });
    },
    [UPDATE_COUPON_LUNCHEON_BATCH_ACTION]({ commit }) {
      return new Promise((resolve, reject) => {
        api.user.getCouponLuncheonsBatch()
          .then((response) => {
            commit(UPDATE_COUPON_LUNCHEONS_BATCH, response.data.data.results);
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    },
    [REMOVE_COUPON_LUNCHEON_BATCH_ACTION]({
      commit,
      dispatch
    }, { id }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', REMOVE_COUPON_LUNCHEON_BATCH_ACTION, { root: true });
        api.user.suppressCouponLuncheonBatch(id)
          .then((response) => {
            commit(UPDATE_COUPON_LUNCHEONS_BATCH, response.data.data.results);
            resolve(response);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', REMOVE_COUPON_LUNCHEON_BATCH_ACTION, { root: true }));
      });
    },
  },
};
