import { getGddError } from 'PotagerLogic/Utils/DeliveryPoint/GroupDeliveryDayErrors';
import WORDINGS from 'PotagerLogic/Constants/Wordings';
import { Context } from 'PotagerLogic/Enums/Context';

export const BT004_ADD_BOX_ERROR = {
  text: WORDINGS.GROUP_DELIVERY_DAY.DELETE.CONTEXT_BASKET,
  buttons: [{
    label: 'Modifier mon point de retrait',
    route: {
      "name": `account-dlp-search-step1-${Context.Basket}`,
      "params": {
        "type": "point-relais"
      }
    }
  }],
  handle(resp) {
    const gdd = resp.data.basket.groupDeliveryDay;
    return { text: getGddError(gdd) };
  },
};

export default {
  default: {
    text: WORDINGS.GROUP_DELIVERY_DAY.DELETE.CONTEXT_BASKET,
  },
  'UserApi.addBox': BT004_ADD_BOX_ERROR,
  'TemporaryCustomerApi.addBox': BT004_ADD_BOX_ERROR,
};
