import { mapGetters } from 'vuex';
import { CLEAR_SUBSCRIPTION_BASKET_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';

import { inAppWebView } from 'Classes/ConfigurationManager';

import PotagerModal from 'UI/PotagerModal';

export default {
  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
      'getRegionId',
    ]
    ),
    ...mapGetters('user', [
      'getSubscriptionBasket',
      'isSubscriptionActive'
    ]), ...mapGetters('tunnel', ['getStepRouteByName',]),
  },

  methods: {
    // If a user is
    // - logged in
    // - not subscribed
    // - has a valid subscription basket (basket + pdr)
    // - not already in the tunnel or on the CGVs
    // => Then we offer to return to the payment step of the tunnel.
    // => If they cancel, we delete their subscription basket.
    trySubscriptionRecovery() {
      if (inAppWebView()) return;
      if (this.$route.matched?.some((r) => ['tunnel', 'cgv'].includes(r.name))) return;
      if (/\/(abonnement|cgv)/.test(document.location.pathname)) return;

      if (!this.isLoggedIn || this.isSubscriptionActive) return;

      const gdd = this.getSubscriptionBasket?.customerSubscription?.groupDeliveryDay;
      const product = this.getSubscriptionBasket?.customerSubscription?.product;
      const hasProduct = !!product?.id;

      const isGDDValid = gdd?.id && this.getRegionId === gdd.regionId && !gdd.isDisabledForSubscription;

      if (!hasProduct || !isGDDValid) return;

      this.$modal.open(PotagerModal, {
        title: 'Il est encore temps de valider votre&nbsp;commande&nbsp;!&nbsp;🌱',
        text: `
      Nous avons conservé le panier et le point relais que vous aviez choisis pour votre abonnement.
      <br>
      Plus qu’une étape avant de recevoir vos fruits & légumes en direct des producteurs pour toute la semaine ! 👩🏼‍🌾
    `,
        buttons: [{
          label: 'Reprendre ma commande',
          route: this.getStepRouteByName('subscriptionPayment'),
        }, {
          label: 'Annuler',
          type: 'cancel',
        }],
      }, (e) => {
        if (e.params?.type === 'close') {
          this.$router.push(this.getStepRouteByName(this.$mq.bp1024 ? 'subscriptionSummary' : 'subscriptionPayment'));
        } else if (['cancel', 'dismiss'].includes(e.params?.type)) {
          this.$store.dispatch(`subscriptionBasket/${CLEAR_SUBSCRIPTION_BASKET_ACTION}`);
        }
      });
    }
  },
};
