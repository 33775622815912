import { EDIT_EDENRED_SETTINGS, LOGOUT_EDENRED, GET_EDENRED_LOGIN_URL } from 'Stores/types/userActionsTypes';
import IconPtcyEdenred from 'Icons/bold/IconPtcyEdenred';
import IconPtcyEdenredColor from 'Icons/color/IconPtcyEdenred';

import ModalPedagogyTicketResto from 'Components/modals/ModalPedagogyTicketResto';

import { inAppWebView } from 'Classes/ConfigurationManager';
import { getResolvedUrl } from 'Classes/utils/RouteUtils';

import { openModal } from 'Plugins/potagerModals';
import { storeDispatch } from 'Services/storeServices';

import { PaymentMethods } from 'PotagerLogic/Enums/PaymentMethods';

export default {
  ...PaymentMethods.Edenred,
  route: { name: 'mon-compte_payment-methods_edenred' },
  defaultIcon: IconPtcyEdenred,
  icons: [IconPtcyEdenred],
  alternativeIcons: [IconPtcyEdenredColor],
  loaders: {
    login: GET_EDENRED_LOGIN_URL,
    logout: LOGOUT_EDENRED,
    editSettings: EDIT_EDENRED_SETTINGS,
  },
  actions: {
    openAddModal(app, onSuccess, onClose) {
      openModal(ModalPedagogyTicketResto,
        {
          name: PaymentMethods.Edenred.name,
          cardIconComponent: IconPtcyEdenred,
          cardIconFullComponent: IconPtcyEdenredColor,
          cardIconColor: PaymentMethods.Edenred.colors.primary,
        },
        (e) => {
          if (typeof onClose === 'function') onClose();
          if (e.params?.type === 'success' && typeof onSuccess === 'function') onSuccess();
        });
    },
    redirect: (routeName = 'mon-compte_payment-methods_edenred') => {
      const redirectUrl = `${getResolvedUrl(routeName)}?from=edenred${inAppWebView() ? `&utm_source=app&token=${localStorage.getItem('token')}` : ''}`;
      storeDispatch(`user/${GET_EDENRED_LOGIN_URL}`, redirectUrl)
        .then(({ data }) => {
          document.location.href = data;
        });
    },
    logout: () => {
      storeDispatch(`user/${LOGOUT_EDENRED}`);
    },
    setDefault: ({ isDefault }) => {
      storeDispatch(`user/${EDIT_EDENRED_SETTINGS}`, {
        usableForSubscription: isDefault,
      });
    },
  },
};
