import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import {
  CANCELLED,
  ONESHOT,
  ORDER_STATUS_SUBSCRIPTION,
  UNPAYED,
} from 'Classes/OrderStatus';

import {
  CANCEL_ORDER_ACTION,
  LOAD_HISTORICAL_ORDERS, RESUME_ORDER_ACTION,
  SWITCH_HISTORICAL_ORDERS_FILTER, UPDATE_DELIVERY_POINT_ORDER_ACTION,
  UPDATE_USER_ORDER,
} from 'Stores/types/userActionsTypes';

import {
  ADD_ORDER,
  RESET_HISTORICAL_ORDER,
  UPDATE_HISTORICAL_ORDER,
  UPDATE_ORDER, UPDATE_USER, UPDATE_USER_ORDER_SUBSCRIPTION,
} from 'Stores/types/userMutationsTypes';

import { UPDATE_BASKET } from 'Stores/types/basketMutationsTypes';

import { api } from 'Plugins/potagerApiClient';

export const defaultState = {
  orders: [],
  historicalOrders: [],
  historicalOrdersOnlyShipped: true,
  historicalOrdersNextPage: 2,
  historicalOrdersNbPages: undefined,
  totalPaidOrders: null,
};

export default {
  state: { ...defaultState },

  getters: {
    getOrders: ({ orders = [] }) => {
      return orders
        .filter((order) => {
          const isOneShot = order.canal === ONESHOT;
          const isSubscription = [ORDER_STATUS_SUBSCRIPTION].includes(order.status);
          const isUnpaid = order.frontStatus === UNPAYED;
          return !(isOneShot && (isSubscription || isUnpaid));
        });
    },
    getEditableOrders: (state) => state.orders.filter((order) => order.isEditable),
    getActiveOrders: (state, getters) => filter(getters.getOrders, (o) => o.status !== CANCELLED),
    getHistoricalOrders: (state) => state.historicalOrders,
    getTotalPaidOrders: (state) => state.totalPaidOrders,
    isHistoricalOrdersOnlyShipped: (state) => state.historicalOrdersOnlyShipped, // #Todo : do better
    hasHistoricalOrdersNextPage: (state) => state.historicalOrdersNextPage && state.historicalOrders.length > 9,
    getNextSubscriptionOrder: (state) => (state.subscription ? state.subscription.nextOrder : null),
    getNextEditableSubscriptionOrder: (state) => (state.subscription ? state.subscription.nextEditableOrder : null),
    getOrderById: (state) => (orderId) => find([...state.orders, ...state.historicalOrders], (o) => parseInt(o.id, 10) === parseInt(orderId, 10)) || null,
    getOrderCoupons: (state, getters) => (orderId) => getters.getOrderById(orderId).coupons || [],
    isFirstOrder: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['session/isLoggedIn']) {
        return true;
      }

      if (rootGetters['wait/is'](LOAD_HISTORICAL_ORDERS)) return false;

      const allOrders = [...getters.getOrders, ...getters.getHistoricalOrders];
      const isAllOrdersEligible = !allOrders
        .find((order) => {
          if (order.status === 'C' || order.status === 'S') return true;
          return (order.status === 'A' || order.status === 'P') && !!order.coupons
            .find((coupon) => coupon.couponUsableOnlyFirstOrder);
        });

      let isTemporaryOrderEligible = true;

      if (getters.getSubscription || getters.getSubscriptionBasket) {
        const temporaryOrder = getters.getSubscription?.temporaryOrder;
        const temporaryBasket = getters.getSubscriptionBasket;
        const temporaryOrderCondition = !temporaryOrder || (!!temporaryOrder && !!temporaryOrder.status && !!(temporaryOrder.coupons && !temporaryOrder.coupons
          .find((coupon) => coupon.couponUsableOnlyFirstOrder)));
        const temporaryBasketCondition = !temporaryBasket || (!!temporaryBasket && !!(temporaryBasket.coupons && !temporaryBasket.coupons
          .find((coupon) => coupon.couponUsableOnlyFirstOrder)));

        isTemporaryOrderEligible = temporaryOrder ? temporaryOrderCondition : temporaryBasketCondition;
      }

      return isAllOrdersEligible && isTemporaryOrderEligible;
    },
    getNeededActionOrders: (state, getters) => getters.getOrders.filter((order) => (order.stripePayment.isPayable && !order.stripePayment.canCustomerWaitForBatch)),
  },

  mutations: {
    [UPDATE_USER_ORDER](state, orders) {
      state.orders = orders;
    },
    [UPDATE_ORDER](state, updatedOrder) {
      if (!state.orders.filter((o) => o.id === updatedOrder.id).length) {
        state.orders = state.orders.concat(updatedOrder);
      } else {
        const newOrders = [...state.orders];
        const index = findIndex(newOrders, (order) => parseInt(order.id, 10) === parseInt(updatedOrder.id, 10));
        newOrders[index] = updatedOrder;
        state.orders = newOrders;
      }
    },
    [UPDATE_HISTORICAL_ORDER](state, historicalOrders) {
      state.historicalOrders = state.historicalOrders.concat(historicalOrders);
    },
    [RESET_HISTORICAL_ORDER](state) {
      state.historicalOrders = [];
    },
    [ADD_ORDER](state, newOrder) {
      state.orders = state.orders.concat(newOrder);
    },
    [UPDATE_USER_ORDER_SUBSCRIPTION](state, order) {
      const index = findIndex(state.orders, (o) => parseInt(o.id, 10) === parseInt(order.id, 10));
      this._vm.$set(state.orders, index, order);
    },
  },

  actions: {
    [UPDATE_DELIVERY_POINT_ORDER_ACTION]({
      commit,
      dispatch
    }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_DELIVERY_POINT_ORDER_ACTION, { root: true });
        api.user.setDeliveryPointOrder(
          payload.orderId,
          payload.regionId,
          payload.groupDeliveryDayId,
          payload.simulation,
          payload.address,
        )
          .then((resp) => {
            commit(UPDATE_USER, resp.data.data);
            resolve(resp)
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_DELIVERY_POINT_ORDER_ACTION, { root: true }));
      });
    },
    [SWITCH_HISTORICAL_ORDERS_FILTER]({
      dispatch,
      state
    }) {
      state.historicalOrdersOnlyShipped = !state.historicalOrdersOnlyShipped;
      dispatch(LOAD_HISTORICAL_ORDERS, { page: 1 });
    },
    [LOAD_HISTORICAL_ORDERS]({
      commit,
      dispatch,
      state
    }, { page = 1 }) {
      if (page !== 'more') {
        commit(RESET_HISTORICAL_ORDER);
      } else if (state.historicalOrdersNextPage !== false) {
        page = state.historicalOrdersNextPage;
      }

      dispatch('wait/start', LOAD_HISTORICAL_ORDERS, { root: true });
      api.order.getHistoricals(state.historicalOrdersOnlyShipped, { page })
        .then((resp) => {
          const {
            results,
            nextPage,
            nbPages
          } = resp.data.data;
          commit(UPDATE_HISTORICAL_ORDER, results);
          state.historicalOrdersNextPage = nextPage;
          state.historicalOrdersNbPages = nbPages;
        })
        .finally(() => dispatch('wait/end', LOAD_HISTORICAL_ORDERS, { root: true }));
    },
    [CANCEL_ORDER_ACTION]({
      commit,
      dispatch
    }, {
      orderId,
      ...param
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', CANCEL_ORDER_ACTION, { root: true });
        api.user.cancelOrder(orderId, param)
          .then((resp) => {
            commit(UPDATE_USER, resp.data.data);
            commit(`basket/${UPDATE_BASKET}`, resp.data.data.basket, { root: true });
            resolve(resp.data.data.orders);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', CANCEL_ORDER_ACTION, { root: true }));
      });
    },
    [RESUME_ORDER_ACTION]({
      commit,
      dispatch
    }, payload) {
      const {
        id = -1,
        simulation = true
      } = payload;
      return new Promise((resolve, reject) => {
        dispatch('wait/start', `${RESUME_ORDER_ACTION}_${id}`, { root: true });
        api.user.resumeOrder(id, simulation)
          .then((resp) => resolve(resp))
          .catch((err) => reject(err))
          .finally((resp) => {
            if (!simulation) {
              commit(UPDATE_USER, resp.data.data);
              commit(`basket/${UPDATE_BASKET}`, resp.data.data.basket, { root: true });
            }
            dispatch('wait/end', `${RESUME_ORDER_ACTION}_${id}`, { root: true })
          });
      });
    },
  },
};
