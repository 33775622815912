import GtmMixin from 'Mixins/GtmMixin';
import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';

import { CANCEL_ORDER_ACTION, RESUME_ORDER_ACTION } from 'Stores/types/userActionsTypes';

import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import { ABONNEMENT, ONESHOT } from 'Classes/OrderStatus';

import ModalOrderResumeOutOfStock from 'Modals/ModalOrderResumeOutOfStock';

import PotagerModal from 'UI/PotagerModal';

export default {
  mixins: [PaymentMethodsMixin, GtmMixin],

  methods: {
    cancelOrder(order) {
      if (order.canal === ABONNEMENT && order.boxes.length === 1 && order.containsSubscriptionBox) {
        this.$router.push({
          name: 'mon-compte_order_suspend_form',
          params: {
            orderId: order.id,
          },
        });
      } else {
        this.showCancelOrderModal(order);
      }
    },
    showCancelOrderModal(order, config = {}) {
      const isOneShot = order.canal === ONESHOT;
      const title = isOneShot ? 'Êtes-vous sûr ?' : 'Avertissement';
      const text = isOneShot ? `Si vous avez payé cette commande avec
    des coupons, ils vous seront rendus. Si vous
    avez effectué un paiement par carte bancaire,
    vous serez remboursé sous forme de coupon.` : (config.text || 'Êtes-vous sûr de vouloir annuler cette commande ?');
      const confirmText = config.confirm || 'Annuler ma commande';
      const cancelText = config.cancel || 'Fermer';
      const orderId = order.id;

      this.$modal.open(PotagerModal, {
        title,
        text,
        buttons: [{
          label: confirmText,
          loading: CANCEL_ORDER_ACTION,
          action: ({ close }) => {
            this.$store.dispatch(`user/${CANCEL_ORDER_ACTION}`, { orderId })
              .then((orders) => {
                const orderCanceled = orders.find((o) => o.id === orderId);
                this.trackRefundTransaction(orderCanceled);
                this.$notify({
                  type: 'success',
                  title: 'Commande annulée',
                  text: `L'annulation a bien été prise en compte. Si vous aviez appliqué des coupons, ils vous ont été rendus.`,
                });
                close();
              })
              .catch((errors) => {
                console.error(errors);
                this.$notify({
                  type: 'error',
                  title: 'Erreur lors de l\'annulation',
                  text: 'Réessayez ou <a href="https://potagercity.my.site.com/serviceclient/s/contactsupport" class="underline">contactez le service client</a> si le problème persiste.'
                });
                close();
              });
          },
        }, {
          label: cancelText,
          loading: CANCEL_ORDER_ACTION,
        }]
      });
    },
    resumeOrder(orderId, simulation = true, response = null) {
      if (simulation && response) {
        const errors = response.warning;

        if (errors.length > 0 && errors[0].code === 'BT018') {
          const products = errors.filter(warn => warn.code === 'BT018')
            .map(warn => warn.metadata);

          this.$events.emit(POPIN_ERROR_EVENT, {
            component: ModalOrderResumeOutOfStock,
            products,
            orderId,
          });

          return Promise.resolve({
            orderId,
            response
          });
        } else {
          return this.resumeOrder(orderId, false);
        }
      }

      return this.$store.dispatch(`user/${RESUME_ORDER_ACTION}`, {
        id: orderId,
        simulation
      })
        .then(resp => {
          if (!resp.data.success) throw resp.data.errors;

          if (!simulation) {
            const order = resp.data.data.orders.find(o => o.id === orderId);
            if (order) this.notifyOrderStatus(order);
          }

          if (simulation) {
            // nextTick to avoid the no-simulation wait/start ito be called before the simulation wait/end
            this.$nextTick(() => this.resumeOrder(orderId, true, resp.data));
          }

          return {
            orderId,
            response: resp.data
          };
        })
        .catch(err => {
          if (!simulation) {
            console.error(err);
            this.$notify({
              type: 'error',
              title: 'Erreur lors de la reprise de la commande',
              text: 'Réessayez ou <a href="https://potagercity.my.site.com/serviceclient/s/contactsupport" class="underline">contactez le service client</a> si le problème persiste.'
            });
          }
          throw err;
        });
    }
  },
};
