<template>
  <panel
    :back-route="backRoute"
    title="Application automatique">
    <coupon-automated
      ref="couponAutomated"
      @configUpdated="onCouponAutomatedUpdate" />

    <template #footer>
      <div class="flex gap-4">
        <potager-button
          :is-loading="$wait.is('UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION')"
          full-width
          label="Valider"
          @onClick="valid" />

        <potager-button
          :to="backRoute"
          full-width
          label="Annuler"
          theme="stroke" />
      </div>
    </template>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerButton from 'UI/PotagerButton';
import PotagerModal from 'UI/PotagerModal';

import Panel from 'Components/panel/Panel';
import CouponAutomated from 'Components/coupons/CouponAutomated';

import MetaInfosService from 'Classes/services/MetaInfoService';

export default {

  components: {
    Panel,
    PotagerButton,
    CouponAutomated,
  },

  data() {
    return {
      backRoute: { name: 'mon-compte_coupons' },
    };
  },

  computed: {
    ...mapGetters('user', [
      'hasSubscriptionEnterpriseDiscount',
      'getSubscriptionEnterpriseDiscountValue',
    ]),
    enterpriseDiscount() {
      if (this.hasSubscriptionEnterpriseDiscount) {
        return {
          couponValue: this.getSubscriptionEnterpriseDiscountValue,
          couponValueType: 'VALUE',
          couponCode: 'Avantage entreprise',
        };
      }
      return null;
    },
  },

  methods: {
    onCouponAutomatedUpdate(resp) {
      if (resp.data.data.coupons.length > 0) {
        this.$modal.open(PotagerModal, {
          title: 'Commandes en cours',
          text: `Notre système a détecté que vous avez déjà une ou plusieurs commandes en cours,
        avec au moins un coupon appliqué automatiquement.
        Vous pouvez le voir dans le détail de votre commande.
        Si ce choix ne vous convient pas, vous avez jusqu'à l’avant-veille de la livraison pour modifier les coupons appliqués.`,
          onCloseRoute: this.backRoute,
          buttons: [
            {
              label: 'Mes commandes',
              route: { name: 'mon-compte_orders' },
            },
            {
              label: 'Fermer',
              theme: 'stroke',
            },
          ],
        });
      } else {
        this.$notify({
          type: 'success',
          title: 'Coupons mis à jour',
          text: 'Vos coupons ont bien été mis à jour.',
        });
        this.$router.push(this.backRoute);
      }
    },
    valid() {
      this.$refs.couponAutomated.onConfigOk();
    },
  },

  head: MetaInfosService.generate({
    title: 'Coupons',
  }),
};
</script>
