import { Context } from 'PotagerLogic/Enums/Context';

export default {
  default: {
    text: 'Vous devez avoir une adresse pour une livraison à domicile.',
    buttons: [{
      label: 'Saisir mon adresse',
      route: {
        "name": `account-dlp-search-step1-${Context.Basket}-home`,
        "params": {
          "type": "point-relais"
        }
      }
    }, {
      label: 'Annuler',
    }],
  }
};
