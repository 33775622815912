<template>
  <div
    :class="{
      'potager-input': true,
      'potager-input--is-disabled': disabled,
      'potager-input--full-width w-full': fullWidth,
      'potager-input--is-loading': isLoading,
      'potager-input--uppercase': uppercase,
      'potager-input--inverted': inverted,
      'potager-input--has-error': isError,
      'potager-input--has-success': isSuccess,
      'potager-input--readonly': readonly,
      'potager-input--external-label': externalLabelCondition,

    }">
    <label
      v-if="externalLabelCondition"
      :class="{
        'potager-input__label': true,
        'potager-input__label--external': externalLabel,
      }"
      :for="name">
      {{ label }}
    </label>

    <component
      :is="label ? 'div' : 'label'"
      :for="name"
      class="potager-input__container">
      <span
        v-if="prefix"
        class="potager-input__prefix"
        @click="$emit('onPrefixClick')">
        {{ prefix }}
      </span>

      <div class="potager-input__input-wrapper">
        <span
          v-if="$slots.icon"
          class="potager-input__prefix-icon"
          @click="$emit('onPrefixClick')">
          <slot name="icon" />
        </span>

        <slot name="input">
          <!-- we don't use component:is because it's not v-model reactive -->
          <input
            v-if="!textarea"
            :id="name"
            ref="input"
            v-bind="inputProps"
            v-model="value"
            @blur="onBlur"
            @focus="onFocus"
            @input="onInput"
            @keyup.down="$emit('onKeyDown')"
            @keyup.up="$emit('onKeyUp')">

          <textarea
            v-else
            :id="name"
            ref="input"
            v-bind="inputProps"
            v-model="value"
            @blur="onBlur"
            @focus="onFocus"
            @input="onInput"
            @keyup.down="$emit('onKeyDown')"
            @keyup.up="$emit('onKeyUp')" />
        </slot>

        <div
          v-if="hasActions || isLoading"
          ref="potagerInputActions"
          :class="[
            'potager-input__actions -mr-1.5',
            { 'pr-1.5': suffix || $slots.suffix}
          ]">
          <icon-ptcy-loader-spinner
            v-if="isLoading && !inlineSubmit"
            class="potager-input__loading mr-1.5" />

          <potager-button
            v-else-if="reset && modelValue"
            :is-disabled="disabled || isLoading"
            is-square
            size="small"
            theme="white"
            type="button"
            @onClick="onReset">
            <template #icon>
              <icon-close />
            </template>
          </potager-button>

          <slot name="action" />
        </div>

        <slot name="innerInput" />
      </div>

      <slot name="suffix">
        <span
          v-if="suffix"
          class="potager-input__suffix">
          {{ suffix }}
        </span>
      </slot>
    </component>

    <potager-info
      v-if="isError"
      size="small"
      status="warning">
      <template #icon>
        <icon-road-sign-warning />
      </template>
      {{ errors[0] }}
    </potager-info>

    <potager-info
      v-else-if="isSuccess"
      size="small"
      status="success">
      <template #icon>
        <icon-check-circle1 />
      </template>
      {{ successes[0] }}
    </potager-info>
  </div>
</template>

<script>
import IconClose from 'Icons/bold/IconClose';
import IconRoadSignWarning from 'Icons/bold/IconRoadSignWarning';
import IconCheckCircle1 from 'Icons/bold/IconCheckCircle1';
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';

import PotagerInfo from 'UI/PotagerInfo';
import PotagerButton from 'UI/PotagerButton';
import InputMixin from 'UI/Form/Input/InputMixin';

import differenceBy from 'lodash/differenceBy';

export default {
  mixins: [InputMixin],

  emits: [
    'onError',
    'onSubmit',
    'onEnter',
    'onFocus',
    'onReset',
    'update:modelValue'
  ],

  data() {
    return {
      style: {},
    };
  },

  components: {
    IconClose,
    IconCheckCircle1,
    IconRoadSignWarning,
    IconPtcyLoaderSpinner,
    PotagerInfo,
    PotagerButton,
  },

  computed: {
    externalLabelCondition() {
      const placeholderCondition = this.placeholder && this.label !== this.placeholder;
      return this.label && (this.modelValue || this.modelValue === 0 || this.externalLabel || placeholderCondition);
    },
    inputProps() {
      return {
        autocomplete: this.autocomplete,
        class: this.getClasses,
        disabled: this.disabled,
        id: this.name,
        list: this.list,
        max: this.max,
        maxlength: this.maxlength,
        min: this.min,
        name: this.name,
        placeholder: !this.noPlaceholder ? (this.placeholder || this.label) : null,
        readonly: this.readonly,
        rows: this.rows,
        step: this.step,
        style: this.style,
        type: this.type,
      };
    },
  },

  watch: {
    constraints: {
      handler(val, oldVal) {
        if (val && oldVal && differenceBy(val, oldVal, 'options.value').length) {
          if (['onInput'].includes(this.constraintsValidation)) {
            this.resetErrors();
            this.showError();
          }
        }
      },
      deep: true,
    },
    errors(val) {
      this.$emit('onError', val);
    },
  },

  mounted() {
    this.$events.on(`request:formError:${this.formId}`, this.showError);
    this.$events.on(`request:inputError:${this.name}`, (error) => this.errors.push(error));
    this.$events.on(`request:inputSuccess:${this.formId}`, this.showSuccess);
    this.$events.on(`request:inputSelect:${this.name}`, this.select);
    this.$events.on(`request:inputFocus:${this.name}`, this.focus);
    this.$events.on(`request:inputBlur:${this.name}`, this.blur);
  },

  beforeUnmount() {
    this.$events.off(`request:formError:${this.formId}`, this.showError);
    this.$events.off(`request:inputError:${this.name}`, this.showError);
    this.$events.off(`request:inputSuccess:${this.formId}`, this.showSuccess);
    this.$events.off(`request:inputSelect:${this.name}`, this.select);
    this.$events.off(`request:inputFocus:${this.name}`, this.focus);
    this.$events.off(`request:inputBlur:${this.name}`, this.blur);
  },
  methods: {
    select() {
      this.focus();
      this.$nextTick(() => {
        this.$refs.input?.setSelectionRange(0, this.modelValue.length);
      });
    },
    focus() {
      this.$refs.input?.focus();
    },
    blur() {
      this.$refs.input?.blur();
    },
    emptyValue() {
      this.$emit('update:modelValue', null);
    },
    onReset() {
      this.emptyValue();
      this.$emit('onReset');
      this.focus();
      this.resetErrors();
      this.resetSuccess();
    },
    onFocus() {
      this.$emit('onFocus', this.modelValue);
    },
    onEnter() {
      this.$emit('onEnter', this.modelValue);
    },
    submit() {
      this.blur();
      this.showError();

      if (!this.errors) {
        this.$emit('onSubmit', this.modelValue);
      }
    },
  },
};
</script>
