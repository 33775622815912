const DEFAULT_WORDING = `
  Vous ne pouvez pas choisir ce jour de retrait car il est actuellement complet.
  Veuillez sélectionner un autre jour et/ou un autre point de retrait.`;

export const BT021_UPDATE_SUBSCRIPTION_BASKET_ERROR = {
  text: DEFAULT_WORDING,
  buttons: [{
    label: 'Changer mon point de retrait',
    route: { name: 'tunnel-delivery-type-step1' },
  }],
};

export default {
  default: {
    text: DEFAULT_WORDING,
  },
  'TemporaryCustomerApi.addBasketSubscription': BT021_UPDATE_SUBSCRIPTION_BASKET_ERROR,
  'UserApi.addSubscriptionToSubscriptionBasket': BT021_UPDATE_SUBSCRIPTION_BASKET_ERROR,
  'UserApi.setSubscriptionProduct': BT021_UPDATE_SUBSCRIPTION_BASKET_ERROR,
};
