<template>
  <potager-modal
    v-bind="$props"
    :buttons="computedButtons"
    :text="computedText" />
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_DELIVERY_POINT_BASKET_ACTION } from 'Stores/types/basketActionsTypes';
import { UPDATE_SESSION_USER_DATA_ACTION } from 'Stores/types/sessionActionsTypes';

import PotagerModal, { props } from 'UI/PotagerModal';

export default {
  components: {
    PotagerModal,
  },

  props: {
    ...props,
    newRegionId: {
      type: Number,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
  },

  computed: {
    ...mapGetters('session', [
      'getRegionId',
    ]),
    computedText() {
      const specificText = this.newRegionId !== this.getRegionId
        ? 'En sélectionnant ce point de retrait, vous changez de région et donc d\'offre.'
        : 'En sélectionnant ce point de retrait, vous ne pourrez pas commander sur l\'offre de cette semaine.';
      return `${specificText} En conséquence, votre panier sera vidé.<br><br><b>Êtes-vous sûr de vouloir continuer ?</b>`;
    },
    computedButtons() {
      return [
        {
          label: 'Ok, j\'ai compris !',
          loading: [UPDATE_SESSION_USER_DATA_ACTION, UPDATE_DELIVERY_POINT_BASKET_ACTION],
          action: this.action,
        },
        {
          label: 'Annuler',
          theme: 'stroke',
          loading: [UPDATE_SESSION_USER_DATA_ACTION, UPDATE_DELIVERY_POINT_BASKET_ACTION],
        },
      ];
    },
  },
};
</script>
