<template>
  <div class="panel-section">
    <p class="panel-section__title justify-center">
      <icon-ptcy-loader-spinner class="text-md mr-2" />
      Traitement de votre commande
    </p>

    <div class="panel-section__p mb-0">
      Votre paiement est en cours de traitement.
      <br>
      Vous allez bientôt être redirigé. Merci de ne pas fermer cette page.
      <br>
      <br>
      <b>
        Une validation 3DSecure peut vous être demandée. Merci de bien vouloir la valider.
      </b>

      <!-- paygreen-container needed on paygreen gateway -->
      <!-- used by PaygreenJs to trigger 3DS -->
      <div id="paygreen-container" />
      <div id="paygreen-methods-container" />
      <div id="paygreen-pan-frame" />
      <div id="paygreen-cvv-frame" />
      <div id="paygreen-exp-frame" />
      <div id="paygreen-reuse-checkbox-container" />
    </div>
  </div>
</template>

<script>
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';

export default {
  components: {
    IconPtcyLoaderSpinner,
  },
};
</script>
