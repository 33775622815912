import ORD0016Handler from 'Classes/error-handler/handlers/ORD0016Handler';

import BT003Handler from 'Classes/error-handler/handlers/BT003Handler';
import BT004Handler from 'Classes/error-handler/handlers/BT004Handler';
import BT005Handler from 'Classes/error-handler/handlers/BT005Handler';
import BT018Handler from 'Classes/error-handler/handlers/BT018Handler';
import BT021Handler from 'Classes/error-handler/handlers/BT021Handler';
import PRO002Handler from 'Classes/error-handler/handlers/PRO002Handler';

import ORDBASK030Handler from 'Classes/error-handler/handlers/ORDBASK030Handler';

import WORDINGS from 'PotagerLogic/Constants/Wordings';

export const errorsMapper = {
  BASK001: 'Votre panier n\'est pas valide.',
  BT001: 'La région associée à votre panier n\'est pas la bonne.',
  BT002: 'Votre panier ne respecte pas les règles de validations des codes promo.',
  BT003: BT003Handler,
  BT004: BT004Handler,
  BT005: BT005Handler,
  BT006: 'Veuillez renseigner votre adresse postale ou un code groupe afin de garantir la livraison dans ce point de vente.',
  BT007: 'Vous ne pouvez pas (ou plus) commander sur ce créneau cette semaine.',
  BT008: 'Votre panier ne doit pas contenir des produits appartenant à l\'offre formules.',
  BT009: 'Vous ne pouvez pas commander sur ce jour de retrait car sa limite a été atteinte. Veuillez sélectionner un autre jour et/ou un autre point de retrait.',
  BT010: 'Votre panier doit avoir un jour de livraison.',
  BT012: 'Ce code promo n\'est utilisable que pour votre première commande.',
  BT013: 'Ce code promo a atteint sa limite de clients et ne peut plus être appliqué.',
  BT014: 'Ce code promo ne peut pas être appliqué car il a atteint le nombre maximum de commandes auquel il peut être associé.',
  BT015: BT018Handler, // soldOut product
  BT016: 'Vous n\'avez pas l\'autorisation d\'utiliser ce code promo.',
  BT017: BT018Handler, // soldOut product
  BT018: BT018Handler, // soldOut product
  BT019: 'Vous ne pouvez pas valider ce panier car il ne contient que des articles à l’unité et il n’est pas possible de commander des articles à l’unité seuls.',
  BT021: BT021Handler,
  BT022: 'Vous ne pouvez pas commander sur ce jour de retrait car sa limite a été atteinte. Veuillez sélectionner un autre jour et/ou un autre point de retrait.',
  BT023: 'Une erreur est survenue',
  BT024: 'Votre commande actuelle n\'atteint pas le montant minimum requis. Pour pouvoir finaliser votre commande, veuillez ajouter d\'autres produits à votre panier.<br><br>Astuce : avec un abonnement actif, le montant minimum n\'est pas requis. Profitez-en !',
  COUP001: 'Ce code promo ne peut pas être appliqué car il a atteint son nombre d\'utilisations.',
  COUP003: 'Ce code promo est invalide ou sa période de validité est dépassée.',
  COUP004: 'Ce code promo ne peut pas être appliqué car il n\'est valable que sur la première commande.',
  COUP005: 'Ce code promo ne peut pas être appliqué car il est déjà associé à un point de retrait ou à un autre client.',
  COUP006: 'Ce code promo a atteint sa limite de clients et ne peut plus être appliqué.',
  ORDBASK001: 'Une commande ne peut pas avoir plus de 2 titres restaurants.',
  ORDBASK002: {
    title: 'Codes non-cumulables',
    text: 'Il n’est pas possible de cumuler des réductions en pourcentage et en euro.'
  },
  ORDBASK003: 'La commande est à 0€, elle ne peut pas accepter de code promo supplémentaire.',
  ORDBASK004: {
    title: 'Code avec minimum d\'achats',
    text: 'Votre commande n’atteint pas le minimum d’achat requis pour son application.'
  },
  ORDBASK005: {
    title: 'Codes non-cumulables',
    text: 'Il n’est pas possible de cumuler 2 réductions en pourcentage.'
  },
  ORDBASK006: 'Le même code promo ne peut pas être appliqué deux fois sur une même commande.',
  ORDBASK007: 'Vous devez appartenir au point de retrait associé au code promo pour l\'ajouter à votre compte ou votre commande.',
  ORDBASK008: 'Le code promo que vous avez appliqué n\'est valable que sur votre première commande.',
  ORDBASK010: 'Votre entreprise ne vous autorise à utiliser qu’une seule carte (code promo) de participation par commande. Veuillez vous rapprocher de la personne de votre entreprise qui gère le contrat pour en savoir plus.',
  ORDBASK009: 'Le code promo appliqué sur votre commande n\'est pas disponible pour votre région.',
  ORDBASK012: 'Nous n’avons pas appliqué le code promo sélectionné car nous n’autorisons qu’un seul code promo “valable uniquement pour une première commande”.',
  ORDBASK013: 'Désolé, ce code promo n’est valable que pour les clients avec un abonnement actif. Pour utiliser ce code promo, veuillez activer un abonnement puis l’appliquer sur la commande qui sera créée suite à l’activation de l’abonnement.',
  ORDBASK014: 'Vous ne pouvez pas appliquer de code promo de parrainage sur votre première commande. Effectuez une première commande, attendez qu’elle soit livrée et vous pourrez ensuite les appliquer sur vos commandes suivantes.',
  ORDBASK015: 'Un code promo de parrainage n’est valable que pour les clients avec un abonnement actif. Pour utiliser ce code promo, veuillez l’appliquer sur la commande qui sera créée suite à l’activation de votre abonnement.',
  ORDBASK016: 'Il n’est pas possible d’appliquer plusieurs codes promo de type “parrainage” ou “bon de réduction” sur une même commande.',
  ORDBASK017: 'Il n’est pas possible d’appliquer plusieurs codes promo de type “bon de réduction” sur une même commande.',
  ORDBASK018: 'Il n’est pas possible d’appliquer plusieurs codes promo de type “parrainage” sur une même commande.',
  ORDBASK019: 'Ce code promo n\'est valable que pour les clients avec un abonnement actif. Pour utiliser ce code promo, veuillez l\'appliquer sur la commande qui sera créée suite à l\'activation de votre abonnement.',
  ORDBASK020: 'Ce code promo n\'est valable que pour une commande comportant au moins un produit à l\'unité. Ajoutez d\'abord un produit à l\'unité à votre commande puis appliquez le code promo.',
  ORDBASK021: 'Ce code promo ne peut pas être appliqué car il n\'est éligible que pour une liste restreinte de produits. Veuillez ajouter un produit éligible à votre commande et réessayez.',
  ORDBASK022: 'Ce code promo est réservé aux premières commandes. Or, plusieurs comptes ont été détectés avec la même carte de paiement. Le code promo n’a donc pas pu être appliqué',
  ORDBASK023: 'Ce code promo n’est pas utilisable pour une livraison sur le point de retrait sélectionné. Pour utiliser ce code promo, veuillez sélectionner un autre lieu de retrait.',
  ORDBASK024: 'Vous ne pouvez pas utiliser ce code promo car il est restreint à un domaine email spécifique et l’adresse email associée à votre compte client n’est pas éligible.',
  ORDBASK025: 'Ce code promo n\'est pas utilisable pour une livraison sur le point de retrait sélectionné. Pour utiliser ce code promo, veuillez sélectionner un autre lieu de retrait.',
  ORDBASK030: ORDBASK030Handler,
  ORD001: 'Votre commande ne doit pas contenir d\'abonnement.',
  ORD002: 'Votre commande doit être en cours de composition.',
  ORD003: 'La date limite de paiement de votre commande est dépassée.',
  ORD004: 'Votre commande ne doit pas être confirmée.',
  ORD005: 'Votre commande ne doit pas être livrée.',
  ORD006: 'Votre commande n\'est plus modifiable.',
  ORD007: 'Le paiement de votre commande est échoué.',
  ORD008: 'Votre commande doit posséder un produit de type abonnement.',
  ORD0013: 'Le code promo n\'est utilisable que pour la première commande.',
  ORD0014: 'Le code promo à atteint son nombre d’utilisations maximum',
  ORD0015: 'Nous avons détecté que vous essayez d’appliquer un code promo valable sur la première commande alors que vous possédez déjà plusieurs comptes utilisant la même carte de paiement. Nous sommes dans le regret de vous signaler que nous n’autorisons pas cette pratique.',
  ORD0016: ORD0016Handler, // soldOut product
  ORD0018: ORD0016Handler, // soldOut product
  TEMPCUS002: 'Vous devez choisir une région.',
  TEMPCUS003: 'Vous devez avoir un jour de livraison.',
  TEMPCUS004: 'Votre abonnement n\'existe pas.',
  TEMPCUS005: 'Votre numéro de téléphone mobile n\'est pas valide.',
  TEMPCUS006: 'Votre adresse email n\'est pas valide.',
  TEMPCUS009: 'Le code parrain n\'est pas valide ou le parrain n\'a pas été trouvé.',
  COUPAS001: 'Ce code promo ne peut plus être utilisé sur votre compte.',
  CS001: 'Le produit choisi n\'est pas éligible à l\'abonnement.',
  CS002: 'Le produit choisi n\'est pas disponible sur votre région.',
  CUS001: 'Vous devez posséder une carte bancaire principale pour être abonné.',
  CUS002: 'Vous devez posséder une carte bancaire principale.',
  CUS003: 'Vous devez êtes abonné.',
  CUS005: `Une erreur a été rencontrée lors de la validation. Veuillez réessayer plus tard ou ${WORDINGS.COMMON.SAV_LINK.FULL} si le problème persiste.`,
  CB001: 'Votre carte bancaire est arrivée à expiration.',
  PRO001: 'Le produit n\'est pas disponible sur la semaine en cours.',
  PRO002: PRO002Handler, // soldOut product
  SB001: 'Veuillez indiquer la commande à modifier.',
  CUSBC001: 'La carte de paiement renseignée est invalide. Veuillez réessayer en vérifiant l’exactitude des informations.',
  CUSCOUP002: 'Ce code promo a atteint le nombre maximum d\'utilisations.',
  GDD001: WORDINGS.GROUP_DELIVERY_DAY.FULL.DEFAULT,
  NonTemporaryEmail: 'Les adresses emails jetables ou temporaires ne sont pas admises.',
};

/**
 * Retrieves the error configuration based on the response.
 *
 * @param {Object} resp - The response object.
 * @returns {Object} The mapped error configuration.
 */
export const getErrorConfig = (resp) => {
  const response = resp?.response ?? resp;
  const error = response.data?.errors?.globals?.[0];
  const caller = response.config?.caller;
  const obfuscatedCaller = caller ? caller
    .replace(/^[A-Z]/, c => c.toLowerCase())
    .replace(/[A-Z]/g, c => `-${c}`)
    .replace(/\.+/g, '_')
    .replace(/[aeiouy]/gi, '')
    .toUpperCase() : 'ERR-UNKNOWN';
  const errorMapping = errorsMapper[error.code];

  let mappedError = {
    title: 'Avertissement',
    text: typeof errorMapping === 'string' ? errorMapping : `Une erreur inattendue est survenue. Veuillez réessayer plus tard ou ${WORDINGS.COMMON.SAV_LINK.FULL} si le problème persiste.`,
    errorCode: (error.code || response.code || 'nCode') + ' - ' + obfuscatedCaller,
  };

  if (typeof errorMapping === 'object') {
    let specificError = errorMapping?.[caller] || errorMapping?.default || errorMapping;
    if (specificError?.handle) specificError = { ...specificError, ...specificError.handle(response) };
    mappedError = { ...mappedError, ...specificError };
  }

  console.info('Error Mapper', {
    errorCode: error.code,
    errorMessage: error.message,
    response: response,
    mappedError: mappedError,
  });
  return mappedError;
};

export default getErrorConfig;
