<template>
  <potager-modal
    v-bind="$props"
    title="Victime de son succès">
    <template #body>
      <span v-html="text" />

      <div class="modal-container__out-of-stock-list">
        <p class="modal-container__out-of-stock-list__subtitle">
          {{ pluralize(products.length, 'produit épuisé', 'produits épuisés') }} :
        </p>

        <p
          v-for="(product, index) in getGroupedProducts"
          :key="index"
          class="modal-container__out-of-stock-list__box">
          <span class="modal-container__out-of-stock-list__box__name">
            {{ product[0].boxName }}
          </span>
        </p>
      </div>
    </template>
  </potager-modal>
</template>

<script>
import { pluralize } from 'PotagerLogic/Formatting';

import PotagerModal, { props } from "UI/PotagerModal";

export default {
  components: {
    PotagerModal,
  },

  props: {
    ...props,
    products: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    getGroupedProducts() {
      return this.products.reduce((acc, product) => {
        const existingProduct = acc.find((p) => p[0].boxId === product.boxId);
        if (existingProduct) {
          existingProduct.push(product);
        } else {
          acc.push([product]);
        }
        return acc;
      }, []);
    },
  },

  methods: {
    pluralize,
  },
};
</script>
