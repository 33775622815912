<template>
  <potager-modal
    name="newsletter-leave-tunnel"
    title="Pas encore convaincu ?"
    :buttons="[{
      label: 'Non merci !',
      route: getLastStepRoute(),
    }]"
    :show-once-expiration="1"
    show-once-cookie>
    <template #body>
      <newsletter-section
        class="overflow-hidden rounded-lg"
        source="Tunnel" />
    </template>
  </potager-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerModal from 'UI/PotagerModal';

import NewsletterSection from 'Components/newsletter/NewsletterSection';

export default {
  name: 'ModalNewsletterLeaveTunnel',

  components: {
    NewsletterSection,
    PotagerModal
  },

  computed: {
    ...mapGetters('tunnel', [
      'getLastStepRoute',
    ]),
  },
};
</script>
