<template>
  <panel
    :is-loading="isLoading"
    title="Panier">
    <template #before>
      <basket-order-announcement />
    </template>

    <template #header>
      <dlp-resume
        :context="context"
        class="mx-auto"
        condensed
        contrasted />
    </template>

    <div
      v-if="getProducts?.length === 0"
      class="text-center">
      <template v-if="getBasketStateType === MessageType.Error">
        <icon-illu-pr class="text-[160px] text-british-racing mb-4 mt-6" />

        <p class="font-semibold mb-4">
          Choisissez un jour de retrait disponible.
        </p>

        <potager-button
          :to="getDlpSearchRoute()"
          is-rounded
          theme="british-racing">
          Modifier mon point de livraison
        </potager-button>
      </template>

      <template v-else>
        <icon-illu-basket class="text-[160px] text-british-racing mb-4 mt-6" />

        <p class="font-semibold mb-4">
          Votre panier est vide...
        </p>

        <potager-button
          v-if="getBasketState !== BasketState.TooLate"
          :to="{ name: 'dashboard' }"
          is-rounded
          theme="british-racing">
          Commencer mon marché
        </potager-button>
      </template>
    </div>

    <template v-if="cartItems.length > 0">
      <div class="panel-section bg-transparent p-0">
        <card-product
          v-for="(box, i) in cartItems"
          :key="`box-${i}`"
          :box="box[0]"
          class="mb-2.5 last:mb-0"
          landscape />
      </div>

      <div class="panel-section panel-section--transparent">
        <div class="flex items-center gap-4">
          <div class="panel-section__separator m-0 flex-1" />

          <potager-button
            is-rounded
            label="Vider mon panier"
            size="small"
            theme="stroke"
            @onClick="openConfirmEmptyBasket" />
        </div>
      </div>

      <basket-receipt-details class="mt-6" />

      <transition name="fade">
        <payment-processing v-if="paymentLoading" />
      </transition>
    </template>

    <template
      v-if="!isClosedStore && getProducts?.length !== 0"
      #footer>
      <basket-pre-footer />

      <div
        v-if="getProducts?.length !== 0"
        class="flex items-center">
        <span class="text-british-racing text-sm mr-2 potager-button potager-button--small bg-white font-normal">
          Total&nbsp;:&nbsp;<b>{{ formatPrice(total) }}</b>
        </span>

        <potager-button
          :is-disabled="!canGoNext"
          :is-loading="isPaymentLoading"
          full-width
          label="Valider ma commande"
          theme="go-green"
          @onClick="nextStep" />
      </div>
    </template>
  </panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { EMPTY_BASKET_ACTION } from 'Stores/types/basketActionsTypes';
import { UPDATE_REQUEST_URL_ACTION } from 'Stores/types/sessionActionsTypes';
import { PAY_BASKET_BY_PAYMENT_METHODS_ACTION } from 'Stores/types/userActionsTypes';

import GtmMixin from 'Mixins/GtmMixin';
import BasketPaymentMixin from 'Mixins/BasketPaymentMixin';

import IconIlluBasket from 'Icons/graphics/IconIlluBasket';
import IconIlluPr from 'Icons/graphics/IconIlluPr';

import PotagerButton from 'UI/PotagerButton';
import PotagerModal from 'UI/PotagerModal';

import Panel from 'Components/panel/Panel';
import DlpResume from 'Components/deliveryPoint/DlpResume';
import CardProduct from 'Components/cards/CardProduct';
import PaymentProcessing from 'Components/paymentMethods/PaymentProcessing';

import BasketReceiptDetails from 'Pages/basket/components/BasketReceiptDetails';
import BasketOrderAnnouncement from 'Pages/basket/components/BasketOrderAnnouncement';
import BasketPreFooter from 'Pages/basket/components/BasketPreFooter';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { getDlpSearchRoute } from 'Classes/utils/DlpRouteUtils';

import { getOrderPrice } from 'PotagerLogic/Utils/Order/OrderPrices';
import { formatPrice } from 'PotagerLogic/Formatting';
import { getGDDNoteTitle, getGddNote } from 'PotagerLogic/Utils/DeliveryPoint/GroupDeliveryDay';

import { MessageType } from 'PotagerLogic/Enums/Messages';
import { Context } from 'PotagerLogic/Enums/Context';
import { BasketState } from 'PotagerLogic/Enums/Basket';

export default {
  mixins: [
    GtmMixin,
    BasketPaymentMixin,
  ],

  components: {
    PaymentProcessing,
    BasketPreFooter,
    BasketReceiptDetails,
    PotagerButton,
    Panel,
    DlpResume,
    CardProduct,
    BasketOrderAnnouncement,
    IconIlluBasket,
    IconIlluPr,
  },

  data() {
    return {
      context: Context.Basket,
      BasketState,
      MessageType,
    };
  },

  computed: {
    ...mapGetters('app', [
      'getOneShotMinimumAmount',
    ]),
    ...mapGetters('dashboard', [
      'isClosedStore',
    ]),
    ...mapGetters('user', [
      'getBankCards',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('basket', [
      'getTotal',
      'getSubTotal',
      'getProducts',
      'getSimulatedOrder',
      'getLinkedOrder',
      'isSubscription',
      'getBasket',
      'getBasketState',
      'getBasketStateType',
    ]),
    paymentLoading() {
      return this.$wait.is(PAY_BASKET_BY_PAYMENT_METHODS_ACTION);
    },
    total() {
      return getOrderPrice(this.getSimulatedOrder || this.getBasket)?.total || 0;
    },
    cartItems() {
      return this.getProducts.reduce((acc, product) => {
        const index = acc.findIndex((item) => item[0].id === product.id);
        if (index === -1) {
          acc.push([product]);
        } else {
          acc[index].push(product);
        }
        return acc;
      }, []);
    },
    canGoNext() {
      return this.isSubscription || this.getOneShotMinimumAmount <= this.getSubTotal;
    },
  },

  methods: {
    formatPrice,
    getDlpSearchRoute,
    ...mapActions('session', [
      UPDATE_REQUEST_URL_ACTION,
    ]),
    nextStep() {
      const goNextStep = () => {
        if (!this.isLoggedIn) {
          this.UPDATE_REQUEST_URL_ACTION({ name: 'basket_payment' });
          this.$router.push({ name: 'register' });
        } else {
          if (!this.getBankCards.length && this.getTotal > 0) {
            this.$router.push({
              name: 'mon-compte_payment-methods_credit-card_add',
              query: {
                context: this.context,
                skipHub: true
              }
            });
          } else if (this.getLinkedOrder) {
            // si c'est une complétion de commande, on valide directement la commande
            this.submitBasketPayment();
          } else {
            this.$router.push({ name: 'basket_payment' });
          }
        }
      };

      const note = getGddNote(this.getBasket?.groupDeliveryDay, { deliveryPoint: this.getBasket?.deliveryPoint });
      if (note) {
        this.$modal.open(PotagerModal, {
          title: getGDDNoteTitle(this.getBasket?.groupDeliveryDay, { deliveryPoint: this.getBasket?.deliveryPoint }),
          text: note,
          buttons: [
            {
              label: 'J\'ai compris',
              action: goNextStep,
            },
          ],
        });
      } else {
        goNextStep();
      }
    },
    emptyBasket() {
      return this.$store.dispatch(`basket/${EMPTY_BASKET_ACTION}`);
    },
    openConfirmEmptyBasket() {
      this.$modal.open(PotagerModal, {
        title: 'Êtes-vous sûr ?',
        text: 'Tous les éléments vont être supprimés de votre panier.',
        buttons: [
          {
            label: 'Confirmer',
            action: this.emptyBasket,
            loading: EMPTY_BASKET_ACTION,
          },
          {
            label: 'Annuler',
            theme: 'stroke',
            loading: EMPTY_BASKET_ACTION,
          },
        ],
      });
    },
  },

  mounted() {
    this.trackViewCart();
  },

  head: MetaInfosService.generate({
    title: 'Panier',
  }),
};
</script>
