import ModalBasketOutOfStock from 'Modals/ModalBasketOutOfStock';

export const PRO02_DEFAULT_ERROR = {
  component: ModalBasketOutOfStock,
  text: 'Ce produit ne peut pas être ajouté à votre commande car il est victime de son succès et n’est plus disponible.',
  handle: (response) => {
    const error = response.data?.errors?.globals?.[0];
    return {
      products: error.products || [{ ...error?.metadata }],
    };
  }
};

export default {
  default: PRO02_DEFAULT_ERROR,
};
