<template>
  <potager-modal v-bind="$props">
    <template #footer-note>
      <p class="text-warm-grey text-xs.5 font-semibold">
        Code erreur : {{ errorCode }}
      </p>
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal, { props as modalProps } from 'UI/PotagerModal';

export const props = {
  ...modalProps,
  errorCode: {
    type: String,
    required: true,
  },
};

export default {
  props,
  components: {
    PotagerModal,
  },
};
</script>
