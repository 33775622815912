<template>
  <div class="flex flex-col items-center max-w-full cursor-auto max-h-[90%]">
    <potager-button
      v-if="closable"
      class="mb-5"
      is-circle
      @onClick="close">
      <template #icon>
        <icon-close class="w-3.5 h-3.5" />
      </template>
    </potager-button>

    <div :class="modalClasses">
      <slot name="content">
        <header
          v-if="!withoutHeader"
          ref="header"
          :class="headerClasses">
          <slot
            :close="close"
            name="header">
            <p class="modal-container__title text-lg font-bold">
              <slot name="title">
                <span v-html="title" />
              </slot>
            </p>
          </slot>
        </header>

        <section
          :class="bodyClasses"
          data-scroll="true">
          <div class="modal-container__body-inner">
            <slot
              :close="close"
              name="body">
              <span v-html="text" />
            </slot>
          </div>
        </section>

        <footer
          v-if="!withoutFooter && (footerNote || computedButtons.length || $slots.footer)"
          ref="footer"
          :class="footerClasses">
          <div class="flex flex-col flex-wrap gap-4">
            <slot
              :close="close"
              name="footer">
              <potager-button
                v-for="(button, index) in computedButtons"
                :key="`modal-button-${index}`"
                :is-loading="button.loading ? $wait.is(button.loading) : undefined"
                :theme="button.theme || 'british-racing'"
                :to="buttonRoute(button)"
                full-width
                @onClick="() => buttonAction(button) ? buttonAction(button)({ close }) : close">
                {{ button.label }}
              </potager-button>
            </slot>

            <slot name="footer-note">
              <p
                v-if="footerNote"
                class="text-xs.5 text-warm-grey italic"
                v-html="footerNote" />
            </slot>
          </div>
        </footer>
      </slot>
    </div>
  </div>
</template>

<script>
import GtmMixin from 'Mixins/GtmMixin';

import { stuckPage } from 'Classes/utils/ScrollUtils';

import IconClose from 'Icons/bold/IconClose';

import PotagerButton from 'UI/PotagerButton';

import Cookies from 'js-cookie';

export const props = {
  name: String,
  title: {
    type: String,
    default: 'Avertissement'
  },
  text: String,
  closable: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    validator: (value) => [
      'small',
      'medium',
      'large'
    ].includes(value),
  },
  withoutFooter: Boolean,
  withoutHeader: Boolean,
  headerExtraClasses: String,
  bodyExtraClasses: String,
  footerExtraClasses: String,
  showOnceCookie: {
    type: Boolean,
    default: false
  },
  showOnceExpiration: {
    type: Number,
    default: 365
  },
  showIterationExpiration: {
    type: Number,
    default: 365
  },
  buttons: {
    type: Array,
    default: () => [
      {
        label: 'Fermer',
        default: true,
      },
    ],
  },
  onCloseRoute: String,
  onCloseAction: Function,
  footerNote: String,
};

export default {
  mixins: [GtmMixin],
  components: {
    IconClose,
    PotagerButton
  },
  props,
  emits: ['close'],
  data: () => ({
    isScrolled: false,
    hasScroll: false
  }),
  computed: {
    computedButtons() {
      return this.buttons
        .filter(btn => !(btn.default && !this.closable));
    },
    showOnceCondition() {
      if (!this.name && this.showOnceCookie) console.error('Modal must have a name for showOnceCookie to work');
      return this.showOnceCookie && this.name;
    },
    modalClasses() {
      return `modal-container__content modal-container__content--is-${this.size}`;
    },
    headerClasses() {
      return [
        'modal-container__header',
        { 'modal-container__header--scrolled': this.isScrolled },
        this.headerExtraClasses,
      ];
    },
    bodyClasses() {
      return [
        'modal-container__body',
        {
          'modal-container__body--without-footer': this.withoutFooter,
          'modal-container__body--without-header': this.withoutHeader
        },
        this.bodyExtraClasses,
      ];
    },
    footerClasses() {
      return [
        'modal-container__footer z-10 align-center justify-center',
        this.footerExtraClasses
      ];
    },
  },
  watch: {
    '$potagerRoute'(route, oldRoute) {
      // oldRoute to allow open modal on mounted
      if (oldRoute?.name) this.close();
    },
  },
  methods: {
    close() {
      this.$modal.close();
      this.trackModal('dismiss');

      const iterations = parseInt(Cookies.get(`${this.name}-iteration`), 10) + 1;
      Cookies.set(`${this.name}-iteration`, iterations, {
        expires: this.showIterationExpiration,
        sameSite: 'Lax'
      });

      if (this.showOnceCondition) {
        Cookies.set(this.name, true, {
          expires: this.showOnceExpiration,
          sameSite: 'Lax'
        });
      }
    },
    trackModal(value) {
      const {
        name,
        event,
        params
      } = this.$parent.tracking || {};
      if (name) {
        this.trackAction({
          event: event || 'Modal',
          name,
          value,
          params
        });
      }
    },
    buttonAction(button) {
      return button.action || this.close;
    },
    buttonRoute(button) {
      return button.route || (button.action ? undefined : this.onCloseRoute);
    }
  },
  mounted() {
    this.trackModal('open');
    stuckPage();
    document.addEventListener('keyup', this.onKeyUp);
    if (!Cookies.get(`${this.name}-iteration`)) {
      Cookies.set(`${this.name}-iteration`, 0, {
        expires: 365,
        sameSite: 'Lax'
      });
    }
  },
  beforeUnmount() {
    stuckPage(this.$potagerRoute?.meta?.isPanel || this.$potagerRoute?.meta?.isFlying || false);
    document.removeEventListener('keyup', this.onKeyUp);
  },
};
</script>
