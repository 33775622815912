import ModalBasketOutOfStock from 'Modals/ModalBasketOutOfStock';

export const BT018_DEFAULT_ERROR = {
  component: ModalBasketOutOfStock,
  text: 'Un ou plusieurs produits présents dans votre panier sont victimes de leur succès et ne sont plus disponibles.',
  buttons: [{
    label: 'Modifier mon panier',
    route: { name: 'basket' },
  }],
  handle: (response) => {
    const error = response.data?.errors?.globals?.[0];
    return {
      products: error.products || [{ ...error?.metadata }],
    };
  }
};

export const BT018_CONFIRM_SHADOW_BASKET_ERROR = {
  title: 'Victime de son succès',
  text: 'Le dernier élément vient d\'être vendu et n’est désormais plus disponible.',
};

export const BT018_RESUME_ORDER_ERROR = {
  title: 'Victime de son succès',
  text: `Votre commande ne peut malheureusement pas être reprise car tous les éléments présents
      sont épuisés. Nous vous suggérons de faire une nouvelle commande à partir des paniers proposés
      et disponibles cette semaine.`,
  buttons: [{
    label: 'Aller sur la boutique',
    route: { name: 'dashboard' },
  }],
};

export const BT018_UPDATE_DELIVERY_POINT_ERROR = {
  title: 'Victime de son succès',
  text: 'Un produit présent dans votre panier est victime de son succès  et n\'est plus disponible pour le jour de livraison choisi. Si vous souhaitez appliquer ce changement, nous devront le retirer de votre panier.',
};

export default {
  default: BT018_DEFAULT_ERROR,
  'ShadowBasketApi.confirm': BT018_CONFIRM_SHADOW_BASKET_ERROR,
  'ShadowBasketApi.addProduct': BT018_CONFIRM_SHADOW_BASKET_ERROR,
  'UserApi.resumeOrder': BT018_RESUME_ORDER_ERROR,
  'TemporaryCustomerApi.setBasketDeliveryPoint': BT018_UPDATE_DELIVERY_POINT_ERROR,
  'UserApi.setBasketDeliveryPoint': BT018_UPDATE_DELIVERY_POINT_ERROR,
};
