<template>
  <panel
    :back-route="backRoute"
    :is-loading="!order"
    :title="title">
    <template #before>
      <status-banner :type="orderStatus.type">
        <span v-html="orderStatus.message" />
      </status-banner>
    </template>

    <template #header>
      <account-order-status
        v-if="order"
        :order="order" />
    </template>

    <template v-if="order">
      <time-slot-note
        :time-slot="order.timeSlot"
        only-delivery-note />

      <div
        v-if="needUserAction || order.timeSlot.hasSubstitute || (order?.id && isOrderPayedStatus(order))"
        class="mb-10">
        <payment-order-action
          v-if="needUserAction"
          ref="paymentOrderAction"
          :order="order" />

        <template v-else>
          <account-orders-substitute-section
            v-if="order.timeSlot.hasSubstitute"
            :order="order" />

          <account-order-gazette
            v-if="order?.id && isOrderPayedStatus(order)"
            :order="order" />
        </template>
      </div>

      <div class="panel-section--transparent flex flex-col gap-4">
        <card-product
          v-for="(box, j) in getBoxesGrouped"
          :key="`box-${j}`"
          :box="box"
          :order="order"
          :small="!box.isTypeFormule"
          landscape>
          <template
            v-if="order.isEditable && !box.isComplementary"
            #layout-after>
            <div class="flex gap-2 mt-2 w-full items-center">
              <div class="panel-section__separator m-0 flex-1" />

              <potager-button
                :to="{
                  name: 'mon-compte_orders_detail_replace',
                  params: { orderId: order.id, productIdToReplace: box.id },
                }"
                is-rounded
                theme="stroke">
                Remplacer mon panier
              </potager-button>
            </div>
          </template>
        </card-product>
      </div>

      <account-orders-receipt-detail :order="order" />

      <payment-section
        :order="order" />

      <dlp-resume
        :context="Context.Order"
        :order="order"
        :readonly="!order.isEditable">
        <p class="panel-section__title">
          Livraison

          <potager-badge
            v-if="!order?.isEditable"
            :theme="orderDeliveryStatus.type"
            rounded
            size="medium">
            {{ orderDeliveryStatus.label }}
          </potager-badge>
        </p>
      </dlp-resume>

      <div
        v-if="showCancelOrderButton || showDownloadBillButton"
        class="panel-section--transparent text-center mt-10">
        <potager-button
          v-if="showCancelOrderButton"
          is-rounded
          theme="british-racing"
          @onClick="cancelOrder(order)">
          Annuler cette commande
        </potager-button>

        <potager-button
          v-if="showDownloadBillButton"
          :is-loading="$wait.is('GET_ORDER_BILL')"
          is-rounded
          theme="british-racing"
          @onClick="downloadBill">
          Télécharger la facture
        </potager-button>
      </div>
    </template>

    <template
      v-if="order?.isResumable && !order?.isEditable"
      #footer>
      <potager-button
        :is-loading="$wait.is(`RESUME_ORDER_ACTION_${order.id}`)"
        full-width
        theme="go-green"
        @onClick="resumeOrder(order.id)">
        Réactiver ma commande
      </potager-button>
    </template>
  </panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { UPDATE_REQUEST_URL_ACTION } from 'Stores/types/sessionActionsTypes';
import { UPDATE_DELIVERY_POINT_BASKET_ACTION, EMPTY_BASKET_ACTION } from 'Stores/types/basketActionsTypes';
import { RESUME_ORDER_ACTION } from 'Stores/types/userActionsTypes';

import PotagerButton from 'UI/PotagerButton';

import Panel from 'Components/panel/Panel';
import DlpResume from 'Components/deliveryPoint/DlpResume';
import CardProduct from 'Components/cards/CardProduct';
import PaymentOrderAction from 'Components/paymentMethods/PaymentOrderAction';
import PaymentSection from 'Components/paymentMethods/PaymentSection';

import AccountOrdersReceiptDetail from 'Pages/account/orders/components/AccountOrdersReceiptDetail';
import AccountOrdersSubstituteSection from 'Pages/account/orders/components/AccountOrdersSubstituteSection';
import AccountOrderStatus from 'Pages/account/orders/components/AccountOrderStatus';
import AccountOrderGazette from 'Pages/account/orders/components/AccountOrderGazette';

import MetaInfosService from 'Classes/services/MetaInfoService';
import {
  ABONNEMENT,
  ONESHOT,
  VALIDATED,
  PAYED,
} from 'Classes/OrderStatus';
import { downloadFile } from 'Classes/utils/FileDownloadUtils';
import { Context } from 'PotagerLogic/Enums/Context';

import OrderActionMixin from 'Mixins/OrderActionMixin';

import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import {
  getOrderDeliveryStatus,
  getOrderStatus,
  isOrderPayedStatus
} from 'PotagerLogic/Utils/Order/OrderStatus';
import StatusBanner from 'Components/statusBanner/StatusBanner';
import { MessageType } from 'PotagerLogic/Enums/Messages';
import PotagerBadge from 'UI/PotagerBadge';
import TimeSlotNote from 'Components/timeSlots/TimeSlotNote';

import { isOrderNeedUserAction } from 'potagerlogic/src/Utils/Order/OrderStatus';

export default {
  mixins: [
    OrderActionMixin,
  ],

  components: {
    TimeSlotNote,
    PotagerBadge,
    StatusBanner,
    PaymentSection,
    AccountOrderGazette,
    AccountOrderStatus,
    AccountOrdersSubstituteSection,
    PotagerButton,
    Panel,
    DlpResume,
    CardProduct,
    PaymentOrderAction,
    AccountOrdersReceiptDetail,
  },

  data: () => ({
    RESUME_ORDER_ACTION,
    EMPTY_BASKET_ACTION,
    UPDATE_DELIVERY_POINT_BASKET_ACTION,
    ABONNEMENT,
    VALIDATED,
    PAYED,
    ONESHOT,
    Context,
    MessageType,
  }),

  computed: {
    ...mapGetters('user', [
      'getOrderById',
    ]),
    order() {
      return this.getOrderById(this.$potagerRoute.params.orderId);
    },
    orderStatus() {
      return getOrderStatus(this.order);
    },
    orderDeliveryStatus() {
      return getOrderDeliveryStatus(this.order);
    },
    needUserAction() {
      return isOrderNeedUserAction(this.order);
    },
    backRoute() {
      return {
        name: 'mon-compte_orders',
        params: { status: this.$potagerRoute.query.status || 'en-cours' },
      };
    },
    getBoxesGrouped() {
      if (!this.order) return null;

      const groupBoxes = groupBy(this.order.boxes, 'id');

      const boxes = uniqBy(
        this.order.boxes.map((box) => ({
          ...box,
          nbItems: groupBoxes[box.id].length,
        })),
        'id'
      );

      return boxes.sort((a, b) => (a.isTypeFormule === b.isTypeFormule ? 0 : a.isTypeFormule ? -1 : 1));
    },
    title() {
      return this.order ? `Commande n°${this.order.number ? this.order.number : this.order.id}` : null;
    },
    showCancelOrderButton() {
      return this.order?.isCancellable && !this.order?.isHistorical;
    },
    showDownloadBillButton() {
      return this.order?.isHistorical && this.order.billUrl;
    },
  },

  methods: {
    isOrderPayedStatus,
    ...mapActions('session', [
      UPDATE_REQUEST_URL_ACTION,
    ]),
    downloadBill() {
      this.$wait.start('GET_ORDER_BILL');
      this.$api.user.getOrderBill(this.order.id)
        .then((res) => {
          const { data } = res;
          downloadFile(data, `Facture-WEB-${this.order.id}.pdf`);
        })
        .finally(() => {
          this.$wait.end('GET_ORDER_BILL');
        });
    },
  },

  mounted() {
    this.UPDATE_REQUEST_URL_ACTION();
  },

  head() {
    return MetaInfosService.generate({
      title: this.title,
    });
  },
};
</script>
